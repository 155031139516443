import React from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';

interface CodeHighlightProps {
    content: string;
    isCodeCompleted: boolean;
}

const CodeHighlight: React.FC<CodeHighlightProps> = ({ content, isCodeCompleted }) => {
    const codeRef = React.useRef(null);

    const [language, setLanguage] = React.useState('');
    const [code, setCode] = React.useState('');

    React.useEffect(() => {
        if (isCodeCompleted && codeRef.current) {
            const [codeLanguage, ...rest] = content.split('\n');
            setLanguage(codeLanguage);
            setCode(rest.join('\n'));
        }
    }, [isCodeCompleted, content]);

    React.useEffect(() => {
        if (code && codeRef.current) {
            hljs.highlightElement(codeRef.current);
        }
    }, [code]);

    return (
        <pre>
            <code ref={codeRef} className={language}>
                {code}
            </code>
        </pre>
    );
};

export default CodeHighlight;
