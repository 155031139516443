import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { useGoPage } from '../../libs/hooks/useRouterNavigate';
import { useChatViewClasses } from '../../styles/chatView';
import Alerts from './Alerts';
import { SideBar } from '../sidebar/Sidebar';

export const ChatView: FC = () => {
    // Check if onboarding is required.
    const { isAssistantCreateRoute, isAssistantEditRoute } = useGoPage();
    const classes = useChatViewClasses();

    return (
        <div className={classes.container}>
            {!isAssistantCreateRoute && !isAssistantEditRoute && <SideBar />}
            <Outlet />
            <Alerts />
        </div>
    );
};
