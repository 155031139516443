import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useUserSettingsMenuClasses = makeStyles({
    root: {
        // marginRight: tokens.spacingHorizontalXL,
    },
    persona: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingVerticalMNudge),
        overflowWrap: 'break-word',
    },
});
