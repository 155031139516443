import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTrigger,
} from '@fluentui/react-components';
import { Icon } from '@iconify/react';
import React, { FC, useState } from 'react';
import { Constants } from '../../Constants';
import { useButtonClasses } from '../../styles/global/button';
import { useDocInfoClasses } from '../../styles/popups/documentInfoPopUp';

interface DocumentInfoProps {
    children: React.ReactNode;
}

export const DocInfoPopUp: FC<DocumentInfoProps> = ({ children }) => {
    const buttonClasses = useButtonClasses();
    const classes = useDocInfoClasses();
    const [isOpen, setIsOpen] = useState(false);

    const onTrigger = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    // Ref needed to close the dialog
    const dialogRef = React.useRef(null);

    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <div
                    onMouseDown={onTrigger}
                    style={{
                        width: 'fit-content',
                        height: 'fit-content',
                        minHeight: '0',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    {children}
                </div>
            </DialogTrigger>
            <DialogSurface className={classes.root} ref={dialogRef}>
                <DialogBody className={classes.dialogbody}>
                    <div className={classes.headerwrapper}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <h3>
                                About - <span style={{ fontWeight: 'normal' }}>Upload documents</span>
                            </h3>
                        </div>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Icon
                                icon="lets-icons:close-round-light"
                                width="20px"
                                height="20px"
                                className={classes.closebutton}
                            />
                        </DialogTrigger>
                    </div>
                    <DialogContent className={classes.contentWrapper}>
                        <div className={classes.infocardwrapper}>
                            <div className={classes.infoHeaderWrapper}>
                                <Icon icon="lets-icons:chield-check-light" className={classes.infoIconWrapper} />
                                Is it safe to upload sensitive information?
                            </div>
                            <p>
                                Yes, your documents are securely stored within Itera&apos;s cloud and cannot be accessed
                                by anyone else.
                            </p>
                        </div>
                        <div className={classes.infocardwrapper}>
                            <div className={classes.infoHeaderWrapper}>
                                <Icon icon="lets-icons:blank-alt-light" className={classes.infoIconWrapper} />
                                File formats for upload
                            </div>
                            <p>.txt, .pdf, .docx, .md, .jpg, .jpeg, .png and .tiff.</p>
                        </div>
                        <div className={classes.infocardwrapper}>
                            <div className={classes.infoHeaderWrapper}>
                                <Icon
                                    icon="lets-icons:info-light"
                                    style={{ transform: 'rotate(180deg)' }}
                                    className={classes.infoIconWrapper}
                                />
                                File upload limits
                            </div>
                            <p>You can upload up to 10 documents per chat, with a maximum size of 20 MB.</p>
                        </div>
                        <div className={classes.infocardwrapper}>
                            <div className={classes.infoHeaderWrapper}>
                                <Icon icon="lets-icons:view-light" className={classes.infoIconWrapper} />
                                Visibility and processing
                            </div>
                            <p>
                                Only you can view your uploaded documents. <br></br>Text content is extracted and
                                indexed within Azure Cognitive Search.
                            </p>
                        </div>
                        <div className={classes.infocardwrapper}>
                            <div className={classes.infoHeaderWrapper}>
                                <Icon icon="lets-icons:trash-light" className={classes.infoIconWrapper} />
                                Deletion of documents or chats
                            </div>
                            <p>
                                To ensure compliance, chat history is deleted after {Constants.CHAT_EXPIRATION_DAYS}
                                days. <br></br>Once deleted, the document is permanently removed from storage.
                            </p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.actionsWrapper}>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button className={buttonClasses.primaryButtonLarge}>Close</Button>
                            </DialogTrigger>
                        </div>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
