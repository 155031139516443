import { makeStyles, shorthands } from '@fluentui/react-components';

export const useSelectPopoverClasses = makeStyles({
    surface: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('8px'),
        ...shorthands.borderRadius('0px', '0px', '4px', '4px'),
        position: 'fixed',
    },
    primarySelection: {
        //backgroundColor: 'var(--neutral-midnight-50)',
        ...shorthands.border('none'),
        fontSize: '14px',
        fontWeight: 'normal',
        fontFamily: 'IBM Plex Sans',
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('8px'),
        ...shorthands.padding('8px', '6px'),
    },
    currentSelection: {
        color: 'var(--neutral-midnight-90)',
        fontSize: '12px',
        fontFamily: 'IBM Plex Sans',
        ...shorthands.padding('8px', '6px'),
    },
    dropdownButton: {
        backgroundColor: 'transparent',
        ...shorthands.border('none'),
        fontWeight: 'normal',
    },
});
