export enum ViewStates {
    Start = 'start',
    StartTutorial = 'startTutorial',
    StartIntro = 'startIntro',
    StartWhoAmI = 'startWhoAmI',
    Exploring = 'exploring',
    ExploringTutorial = 'exploringTutorial',
    ExploringNewAssistant = 'exploringNewAssistant',
    ExploringNewAssistantTutorial = 'exploringNewAssistantTutorial',
    Chat = 'chat',
    ChatTutorial = 'chatTutorial',
}
