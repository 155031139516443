import { makeStyles, shorthands } from '@fluentui/react-components';

export const useDocInfoClasses = makeStyles({
    root: {
        width: '800px',
        maxWidth: '2000px',
        ...shorthands.padding('0'),
        ...shorthands.borderRadius('0'),
        backgroundColor: 'white',

        '& *': {
            fontFamily: 'IBM Plex Sans',
        },
    },
    dialogbody: {
        display: 'flex',
        flexDirection: 'column',
    },
    headerwrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...shorthands.padding('16px', '24px'),
        ...shorthands.borderBottom('1px', 'solid', 'var(--neutral-midnight-60)'),
        alignItems: 'center',
    },

    closebutton: {
        cursor: 'pointer',
    },

    contentWrapper: {
        ...shorthands.padding('24px'),
        ...shorthands.borderBottom('1px', 'solid', 'var(--neutral-midnight-60)'),
        ...shorthands.gap('8px'),
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin('0', '-1px'),
    },
    infocardwrapper: {
        backgroundColor: 'var(--neutral-midnight-50)',
        ...shorthands.borderRadius('4px'),
        ...shorthands.padding('8px'),
        ...shorthands.gap('8px'),
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            color: 'var(--primary-sea-at-night-100)',
        },
    },
    infoHeaderWrapper: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('8px'),
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        color: 'var(--primary-sea-at-night-100)',
        alignItems: 'center',
    },
    infoIconWrapper: {
        backgroundColor: 'var(--core-jade)',
        ...shorthands.borderRadius('50%'),
        ...shorthands.padding('3px'),
        width: '18px',
        height: '18px',
    },
    actionsWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        ...shorthands.padding('12px', '24px'),
    },
});
