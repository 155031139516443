import { makeStyles, shorthands } from '@fluentui/react-components';

export const useInputClasses = makeStyles({
    description: {
        ...shorthands.borderRadius('4px'),
        ...shorthands.borderBottom('1px solid #EDEDED'),
        height: '150px',
        boxShadow: '0',
        marginBottom: '24px',
        maxWidth: '1000px',
        scrollbarWidth: 'thin',
        scrollbarColor: 'var(--neutral-midnight-70) #fff',

        '& *': {
            fontFamily: 'IBM Plex Sans',
        },
    },

    smallInput: {
        ...shorthands.borderRadius('4px'),
        ...shorthands.borderBottom('1px solid #EDEDED'),
        boxShadow: '0',
        height: '50px',
        fontSize: '20px',
        fontFamily: 'courier',
        width: '100%',

        '& *': {
            fontFamily: 'IBM Plex Sans',
            maxHeight: '50px',
        },
    },
});
