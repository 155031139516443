import { Icon } from '@iconify/react';
import React from 'react';

import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import chatListStyles from '../../components/sidebar/chatlist.module.scss';
import { useChat } from '../../libs/hooks';
import tooltipStyles from '../global/tooltip.module.scss';
import { PopUpDialog } from '../popups/PopUpDialog';
interface IListItemActionsProps {
    chatId: string;
    header: string;
    onEditTitleClick: () => void;
}

export const ListItemActions: React.FC<IListItemActionsProps> = ({ chatId, onEditTitleClick, header }) => {
    const { deleteChat } = useChat('ListItemActions');

    const onDeleteChat = () => {
        void deleteChat(chatId, header);
    };

    return (
        <div className={chatListStyles['actions-wrapper']}>
            <SlTooltip content={'Edit chat name'} className={tooltipStyles['sapience-tooltip']}>
                <div
                    className={chatListStyles['action-icon-wrapper']}
                    aria-label="Edit chat name"
                    onClick={onEditTitleClick}
                    data-testid="editChatTitleButtonSimplified"
                >
                    <Icon icon="lets-icons:edit-light" width={24} height={24} />
                </div>
            </SlTooltip>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PopUpDialog
                    header="Delete chat"
                    content={`Are you sure you want to delete this chat: ${header}? This action cannot be undone.`}
                    buttonText="Delete"
                    onAction={onDeleteChat}
                    canHide={false}
                >
                    <SlTooltip content={'Delete chat session'} className={tooltipStyles['sapience-tooltip']}>
                        <div className={chatListStyles['action-icon-wrapper']} aria-label="Delete chat session">
                            <Icon icon="lets-icons:trash-light" width={24} height={24} />
                        </div>
                    </SlTooltip>
                </PopUpDialog>
            </div>
        </div>
    );
};
