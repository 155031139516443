import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import AppLayout from '../components/AppLayout';
import { ChatWindow } from '../components/chat/ChatWindow';
import TutorialModal from '../components/modal/TutorialModal';
import AssistantCreator from '../components/views/AssistantCreator';
import AssistantHub from '../components/views/AssistantHub';
import ErrorPage from '../components/views/ErrorPage';
import { StartPage } from '../components/views/startpage/StartPage';

const tutorialChildren = [
    {
        path: 'tutorial',
        element: <Navigate to="1" />,
    },
    {
        path: 'tutorial/:slideNr',
        element: <TutorialModal />,
    },
];

export const router = createBrowserRouter([
    {
        path: '/',
        element: <AppLayout />,
        errorElement: <ErrorPage />,
        children: [
            ...tutorialChildren,
            {
                index: true,
                element: <Navigate to="start" />,
            },
            {
                path: 'start',
                element: <StartPage />,
                children: tutorialChildren,
            },

            {
                path: 'chat',
                element: <Navigate to="/start" />,
            },
            {
                path: 'chat/:selectedId',
                element: <ChatWindow />,
                children: tutorialChildren,
            },
            {
                path: 'assistant',
                element: <Outlet />,
                children: [
                    {
                        path: 'create',
                        element: <AssistantCreator />,
                        children: tutorialChildren,
                    },
                    {
                        path: 'edit/:assistantId',
                        element: <AssistantCreator />,
                        children: tutorialChildren,
                    },
                    {
                        path: 'manage',
                        element: <AssistantHub />,
                        children: tutorialChildren,
                    },
                ],
            },
        ],
    },
]);
