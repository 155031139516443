import { Button, Tooltip } from '@fluentui/react-components';
import { Icon } from '@iconify/react';
import SlTabGroup from '@shoelace-style/shoelace/dist/components/tab-group/tab-group';
import { FC } from 'react';

import { useCreationCompClasses } from '../../../styles/assistantCreation/creationComponents';
import { useButtonClasses } from '../../../styles/global/button';
import { PopUpDialogNA } from '../../popups/PopUpDialogNoAction';
import { GPTServiceId } from '../../utils/GPTModels';
import { neighborsTabsNames } from './helper';
import './tabPageWrappers.scss';

interface SummaryTabProps {
    assistantName: string;
    workDescription: string;
    creativityLevel: string;
    modelName: GPTServiceId;
    setName: (assistantName: string) => void;
    handleCreate: () => void;
    isLoading: boolean;
    tabGroupRef: React.RefObject<SlTabGroup>;
    currentTabName: string;
}

const SummaryTab: FC<SummaryTabProps> = ({
    assistantName,
    workDescription,
    creativityLevel,
    modelName,
    handleCreate,
    isLoading,
    tabGroupRef,
    currentTabName,
}) => {
    const onBack = () => {
        const { previous } = neighborsTabsNames[currentTabName];
        tabGroupRef.current?.show(previous);
    };

    const buttonClasses = useButtonClasses();
    const compclasses = useCreationCompClasses();

    const PopUpController: FC = () => {
        const isNameMissing = !assistantName.trim();
        const isPromptMissing = !workDescription.trim();
        let popUpMessage = '';
        if (!isNameMissing && isPromptMissing) popUpMessage = 'Your assistant needs a prompt.';
        if (isNameMissing && !isPromptMissing) popUpMessage = 'Your assistant needs a name.';
        if (isNameMissing && isPromptMissing) popUpMessage = 'Your assistant needs a prompt and a name.';

        if (popUpMessage)
            return (
                <PopUpDialogNA
                    header="Ooops. Something is missing."
                    content={popUpMessage}
                    canHide={false}
                    onAction={handleCreate}
                >
                    <Button className={buttonClasses.primaryButtonLarge} aria-label="Save assistant">
                        Save
                    </Button>
                </PopUpDialogNA>
            );

        return (
            <Button
                disabled={isLoading}
                onClick={handleCreate}
                className={buttonClasses.primaryButtonLarge}
                aria-label="Save assistant"
            >
                Save
            </Button>
        );
    };

    return (
        <div className="tab-wrapper">
            <div className="content-wrapper">
                <div>
                    <h2 style={{ margin: '0' }}>You have successfully created your assistant. </h2>
                    <div>
                        <div className={compclasses.summaryInfoCard} style={{ margin: '16px 0' }}>
                            <div style={{ margin: 'auto 8px auto 0 ' }}>
                                <Icon icon="lets-icons:info-fill" width="20px" height="20px" color="var(--core-jade)" />
                            </div>
                            <div>
                                Let&apos;s review your settings to ensure everything is set up just the way you want it.
                                <ul>
                                    <li>When you save you can create new chats with these pre-set settings. </li>
                                    <li>
                                        You can edit the assistant&apos;s settings anytime, ensuring your assistant
                                        remains relevant over time.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={compclasses.summarySegment} style={{ marginBottom: '16px' }}>
                        <div className="summary-header-wrapper">
                            <h4>1. Prompt</h4>
                            <Tooltip
                                withArrow
                                content={
                                    'A prompt is a way to guide the conversation and provide specific instructions to get the desired response from your assistant.'
                                }
                                relationship={'description'}
                                aria-label="prompt tip"
                            >
                                <Icon
                                    icon="lets-icons:info-light"
                                    style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                                />
                            </Tooltip>
                        </div>
                        <p className={compclasses.promptSummaryContainer} style={{ marginBottom: '16px' }}>
                            {workDescription}
                        </p>
                    </div>
                    <div className={compclasses.summarySegment} style={{ marginBottom: '16px' }}>
                        <div className="summary-header-wrapper">
                            <h4>2. Creativity</h4>
                            <Tooltip
                                withArrow
                                content={
                                    'Adjusting the creativity affects how varied the response from the assistant will be'
                                }
                                relationship={'description'}
                                aria-label="creativity tip"
                            >
                                <Icon
                                    icon="lets-icons:info-light"
                                    style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                                />
                            </Tooltip>
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            {creativityLevel === '0.1' ? (
                                <p>
                                    More precise
                                    <span style={{ color: 'var(--neutral-midnight-90)' }}> - For factual tasks.</span>
                                </p>
                            ) : creativityLevel === '0.5' ? (
                                <p>
                                    More balanced
                                    <span style={{ color: 'var(--neutral-midnight-90)' }}> - For general use.</span>
                                </p>
                            ) : (
                                <p>
                                    More creative
                                    <span style={{ color: 'var(--neutral-midnight-90)' }}>- For innovative tasks.</span>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={compclasses.summarySegment} style={{ marginBottom: '16px' }}>
                        <div className="summary-header-wrapper">
                            <h4>3. Model</h4>
                            <Tooltip
                                withArrow
                                content={'Model decision impacts assistant performance and task capabilities.'}
                                relationship={'description'}
                                aria-label="model selection tip"
                            >
                                <Icon
                                    icon="lets-icons:info-light"
                                    style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                                />
                            </Tooltip>
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            {modelName === 'gpt4oMini' ? (
                                <p>
                                    GPT-4o mini
                                    <span style={{ color: 'var(--neutral-midnight-90)' }}>
                                        - Fast, efficient, cost effective.
                                    </span>
                                </p>
                            ) : (
                                <p>
                                    GPT-4o
                                    <span style={{ color: 'var(--neutral-midnight-90)' }}>
                                        - Smart, powerful, accurate.
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={compclasses.summarySegment} style={{ marginBottom: '16px', border: 'none' }}>
                        <div className="summary-header-wrapper">
                            <h4>4. Name</h4>
                            <Tooltip
                                withArrow
                                content={'Choose a unique name that sets this assistant apart from others.'}
                                relationship={'description'}
                                aria-label="assistant name information"
                            >
                                <Icon
                                    icon="lets-icons:info-light"
                                    style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                                />
                            </Tooltip>
                        </div>
                        <p style={{ marginBottom: '16px' }}>{assistantName}</p>
                    </div>
                </div>
                <div>
                    <div className="button-wrapper">
                        <Button className={buttonClasses.secondaryButtonLarge} onClick={onBack}>
                            Back
                        </Button>
                        <PopUpController />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SummaryTab;
