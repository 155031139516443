import React from 'react';
import { Outlet } from 'react-router-dom';

import PACreation from '../chat/personal-assistant/PACreation';

const AssistantCreator: React.FC = () => {
    return (
        <div
            style={{
                width: '100%',
                padding: '24px',
                backgroundColor: '#F9F9F9',
            }}
        >
            <PACreation />
            <Outlet />
        </div>
    );
};

export default AssistantCreator;
