import { Button, Tooltip } from '@fluentui/react-components';
import { Icon } from '@iconify/react';
import SlTabGroup from '@shoelace-style/shoelace/dist/components/tab-group/tab-group';
import { FC } from 'react';

import { useCreationCompClasses } from '../../../styles/assistantCreation/creationComponents';
import { useAHClasses } from '../../../styles/assistantHub/assHubElements';
import { useButtonClasses } from '../../../styles/global/button';
import { GPTServiceId } from '../../utils/GPTModels';
import { neighborsTabsNames } from './helper';
import './tabPageWrappers.scss';

interface SelectGPTModelProps {
    isSelected?: boolean;
    modelName: GPTServiceId;
    tabGroupRef: React.RefObject<SlTabGroup>;
    setModelName: (modelName: GPTServiceId) => void;
    currentTabName: string;
}

const SelectGPTModel: React.FC<SelectGPTModelProps> = ({ tabGroupRef, setModelName, modelName, currentTabName }) => {
    const buttonClasses = useButtonClasses();
    const AHClasses = useAHClasses();
    const creationCompClasses = useCreationCompClasses();

    const { previous, next } = neighborsTabsNames[currentTabName];

    const onBack = () => {
        tabGroupRef.current?.show(previous);
    };

    const onNext = () => {
        tabGroupRef.current?.show(next);
    };

    const onSelectGPT4 = () => {
        setModelName('gpt4o');
    };

    const onSelectGPT4oMini = () => {
        setModelName('gpt4oMini');
    };

    const CheckIcon: FC = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle cx="12" cy="12" r="9" fill="#37E17B" />
                    <path d="M8 12L11 15L16 9" stroke="black" />
                </svg>
            </div>
        );
    };
    const WarningIcon: FC = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px' }}>
                <div
                    style={{
                        backgroundColor: 'var(--core-gold)',
                        borderRadius: '50%',
                        height: '18px',
                        width: '18px',
                        margin: 'auto',
                        textAlign: 'center',
                        fontWeight: 'semibold',
                        alignItems: 'center',
                    }}
                >
                    <span>!</span>
                </div>
            </div>
        );
    };

    const modelsDescription = [
        {
            title: 'GPT-4o',
            shortDescription: ['Fast and efficient', 'Your go-to AI'],
            key: 'gpt4o',
            onClickHandler: onSelectGPT4,
            description: [
                { iconType: 'check', text: 'Best for advanced, demanding tasks' },
                { iconType: 'check', text: 'Detailed & context-aware' },
                { iconType: 'check', text: 'Faster' },
                { iconType: 'warning', text: 'More resource-intensive' },
            ],
        },
        {
            title: 'GPT-4o mini',
            shortDescription: [],
            key: 'gpt4oMini',
            onClickHandler: onSelectGPT4oMini,
            description: [
                { iconType: 'check', text: 'Ideal for everyday questions and tasks' },
                { iconType: 'check', text: 'Effective on uploading documents and information retrieval' },
                { iconType: 'check', text: 'Efficient and cost-effective' },
                { iconType: 'warning', text: 'Less depth in complex or nuanced topics' },
            ],
        },
    ];

    return (
        <div className="tab-wrapper">
            <div className="content-wrapper">
                <div>
                    <div className="header-wrapper" style={{ marginBottom: '16px' }}>
                        <h2
                            style={{
                                margin: '0',
                            }}
                        >
                            Suggested AI model for your assistant
                        </h2>
                        <Tooltip
                            withArrow
                            content={'Model decision impacts assistant performance and task capabilities.'}
                            relationship={'description'}
                            aria-label="model selection tip"
                        >
                            <Icon
                                icon="lets-icons:info-light"
                                style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                            />
                        </Tooltip>
                    </div>
                    <p className={AHClasses.description} style={{ marginBottom: '16px' }}>
                        GPT-4o is an excellent starting point. Feel free to revisit and make adjustments whenever you
                        like.
                    </p>
                    <ul
                        className="cards-container"
                        style={{ marginBottom: '24px', listStyleType: 'none', paddingLeft: 0 }}
                    >
                        {modelsDescription.map(({ title, shortDescription, key, onClickHandler, description }) => (
                            <li
                                key={key}
                                onClick={onClickHandler}
                                className={creationCompClasses.creativityCard}
                                style={{
                                    backgroundColor:
                                        modelName === key
                                            ? 'var(--extended-grass-in-bright-light)'
                                            : 'var(--neutral-midnight-50)',
                                }}
                            >
                                <div className="model-card-header-wrapper" style={{ height: '3em' }}>
                                    <h2>{title}</h2>
                                    <ul
                                        style={{
                                            textAlign: 'right',
                                            lineHeight: '22px',
                                            listStyleType: 'none',
                                            paddingLeft: 0,
                                        }}
                                    >
                                        {shortDescription.map((shortDescriptionPoint, i) => (
                                            <li key={`${key}-short-description-${i}`}>{shortDescriptionPoint}</li>
                                        ))}
                                    </ul>
                                </div>
                                <hr style={{ margin: '16px 0' }}></hr>
                                <ul
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                        listStyleType: 'none',
                                        paddingLeft: 0,
                                    }}
                                >
                                    {description.map(({ iconType, text }, index) => (
                                        <li
                                            key={`${key}-description-${index}`}
                                            style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                                        >
                                            {iconType === 'check' ? <CheckIcon /> : <WarningIcon />}
                                            {text}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="button-wrapper">
                    <Button className={buttonClasses.secondaryButtonLarge} onClick={onBack}>
                        Back
                    </Button>
                    <Button className={buttonClasses.primaryButtonLarge} onClick={onNext}>
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SelectGPTModel;
