import { Button, Checkbox, Popover, PopoverProps, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { FC, useEffect, useState } from 'react';
import { useButtonClasses } from '../../styles/global/button';
import { usePopOverClasses } from '../../styles/popups/popOver';

interface POContentProps {
    header: string;
    content: string | JSX.Element;
    buttonText: string;
    onAction: () => void;
    children: React.ReactNode;
    canHide: boolean;
}

export const POContent: FC<POContentProps & PopoverProps> = ({
    header,
    content,
    buttonText,
    onAction,
    children,
    canHide,
    ...props
}) => {
    const buttonClasses = useButtonClasses();
    const poClasses = usePopOverClasses();
    const [isOpen, setIsOpen] = useState(false);
    const [isDoNotShowAgain, setIsDoNotShowAgain] = useState(false);

    // For closing the popover
    const onCancel = () => {
        setIsOpen(false);
        localStorage.setItem(header + '-isDoNotShowAgain', isDoNotShowAgain ? 'true' : 'false');
    };

    // Saving the state of the checkbox in browser
    const handleCheckBoxChange = () => {
        if (isDoNotShowAgain) {
            setIsDoNotShowAgain(false);
        } else {
            setIsDoNotShowAgain(true);
        }
    };

    // Fetching the state of the checkbox from browser
    useEffect(() => {
        const isDoNotShowAgainLocalStorage = localStorage.getItem(header + '-isDoNotShowAgain');
        if (isDoNotShowAgainLocalStorage) {
            setIsDoNotShowAgain(true);
        }
    }, []);

    // Trigger for opening the popover
    const onTrigger = (event: React.MouseEvent<HTMLDivElement>) => {
        if (isDoNotShowAgain) {
            onAction();
        } else {
            event.preventDefault();
            setIsOpen(!isOpen);
        }
    };

    const WarningIcon: FC = () => {
        const color = canHide ? 'var(--core-jade)' : 'var(--status-error)';
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="9" stroke={color} />
                <path
                    d="M12.5 16.5C12.5 16.7761 12.2761 17 12 17C11.7239 17 11.5 16.7761 11.5 16.5C11.5 16.2239 11.7239 16 12 16C12.2761 16 12.5 16.2239 12.5 16.5Z"
                    fill="black"
                />
                <path d="M12 14V7" stroke="black" />
            </svg>
        );
    };

    return (
        <Popover {...props} open={isOpen} withArrow positioning={'above-start'}>
            <PopoverTrigger>
                <div onMouseDown={onTrigger}>{children}</div>
            </PopoverTrigger>

            <PopoverSurface tabIndex={-1} style={{ padding: '24px' }}>
                <div style={{ width: '300px' }}>
                    <div className={poClasses.headerwrapper}>
                        <div className={poClasses.titlewrapper}>
                            <WarningIcon />
                            <h3>{header}</h3>
                        </div>
                        <button onClick={onCancel} className={poClasses.closebutton}>
                            <Dismiss24Regular />
                        </button>
                    </div>
                    <p className={poClasses.content} style={{ marginTop: '14px' }}>
                        {content}
                    </p>
                    <div className={poClasses.actionswrapper} style={{ marginTop: '14px' }}>
                        <Checkbox
                            label="Do not show again"
                            onChange={handleCheckBoxChange}
                            style={{ display: canHide ? 'flex' : 'none' }}
                            className={poClasses.checkbox}
                        />
                        <div className={poClasses.buttonswrapper}>
                            <Button className={buttonClasses.secondaryButton} onClick={onCancel}>
                                Cancel
                            </Button>
                            <Button
                                className={buttonClasses.primaryButton}
                                onClick={() => {
                                    onAction();
                                    onCancel();
                                    localStorage.setItem(
                                        header + '-isDoNotShowAgain',
                                        isDoNotShowAgain ? 'true' : 'false',
                                    );
                                }}
                                style={{ minWidth: '0' }}
                            >
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                </div>
            </PopoverSurface>
        </Popover>
    );
};
