import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useAlertsClasses = makeStyles({
    alert: {
        fontWeight: tokens.fontWeightRegular,
        color: tokens.colorNeutralForeground1,
        backgroundColor: tokens.colorNeutralBackground6,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
    },
    actionItems: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap(tokens.spacingHorizontalMNudge),
    },
    button: {
        alignSelf: 'center',
    },
});