import { Icon } from '@iconify/react';
import SlSwitch from '@shoelace-style/shoelace/dist/react/switch';
import React from 'react';

import { useAssistants } from '../../libs/hooks/useAssistants';
import { useGoPage } from '../../libs/hooks/useRouterNavigate';
import { IAssistant } from '../../libs/models/Assistants';
import { useAssistantByIdSelector } from '../../redux/app/hooks';
import { PopUpDialog } from '../popups/PopUpDialog';

import styles from './PAButton.module.scss';

export const CreatePAButton: React.FC = () => {
    const { goCreateAssistant } = useGoPage();

    return (
        <div
            aria-label="Create New Assistant Button"
            id="newAssistantButton"
            className={styles.container}
            onClick={goCreateAssistant}
        >
            <img src={'/add.svg'} className={styles.icon} />
            <span className={styles.textContainer}>Create an assistant</span>
        </div>
    );
};

interface PAItemProps {
    assistantId: string;
}

export const PAItem: React.FC<PAItemProps> = ({ assistantId }) => {
    const { deleteAssistantAsync, editAssistantAsync } = useAssistants('PAItem');
    const assistant: IAssistant = useAssistantByIdSelector(assistantId);
    const { goEditAssistant } = useGoPage();

    const handleActivate = () => {
        editAssistantAsync(
            assistant.title,
            assistant.systemDescription,
            assistant.responseTemperature,
            assistantId,
            !(assistant.active as boolean),
            assistant.serviceId,
        ).catch((e: Error) => {
            console.error(e);
        });
    };

    const handleEdit = () => {
        goEditAssistant(assistantId);
    };

    const handleDelete = () => {
        deleteAssistantAsync(assistantId).catch((e: Error) => {
            console.error(e);
        });
    };

    return (
        <div aria-label={`Personal assistant ${assistant.title}`} className={styles.assistant}>
            <SlSwitch
                aria-label="Switch assistant"
                checked={assistant.active}
                onSlChange={handleActivate}
                className={styles.switch}
            >
                {assistant.title}
            </SlSwitch>
            <div className={styles.editDelButtons}>
                <Icon icon="lets-icons:edit-light" height="24px" width="24px" onClick={handleEdit} />
                <PopUpDialog
                    header="Delete assistant"
                    content={`Are you sure you want to delete this assistant: ${assistant.title}?`}
                    buttonText="Delete"
                    onAction={handleDelete}
                    canHide={false}
                >
                    <Icon icon="lets-icons:trash-light" height="24px" width="24px" />
                </PopUpDialog>
            </div>
        </div>
    );
};
