export interface GPTDescription {
    title: string;
    summaryItems: string[];
    listItems: string[];
}

export type GPTServiceId = 'gpt4oMini' | 'gpt4o';

export const gpt4oMiniDescription: GPTDescription = {
    title: 'GPT-4o mini',
    summaryItems: ['Fast and Efficient', 'Your Go-To AI'],
    listItems: [
        'GPT-4o mini: Versatile for a broad range of uses.',
        'Common Queries: Ideal for everyday questions and tasks.',
        'Efficient and Cost-Effective: Great for regular assistance.',
        'Limitation: Less depth in complex or nuanced topics.',
    ],
};

export const gpt4oDescription: GPTDescription = {
    title: 'GPT-4o',
    summaryItems: ['Advanced and In-Depth', 'For Specialized Needs'],
    listItems: [
        'Use GPT-4o: Best for advanced, demanding tasks.',
        'Detailed and Context-Aware: Masters uanced interactions.',
        'Consideration: More resource-intensive, can be slower.',
        'Cost Factor: 15x more expensive, use with caution.',
    ],
};

export const cardArray: GPTDescription[] = [gpt4oMiniDescription, gpt4oDescription];
