import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { SharedStyles } from "../../global/styles";

export const useTabViewClasses = makeStyles({
    root: {
        ...shorthands.margin(tokens.spacingVerticalM, tokens.spacingHorizontalM),
        ...SharedStyles.scroll,
        display: 'flex',
        flexDirection: 'column',
    },
    footer: {
        paddingTop: tokens.spacingVerticalL,
    },
});