import { makeStyles, shorthands } from '@fluentui/react-components';

export const useEditClasses = makeStyles({
    promptfield: {
        ...shorthands.border('1px', 'solid', 'var(--neutral-midnight-70)'),
        scrollbarWidth: 'thin',
        scrollbarColor: 'var(--neutral-midnight-70) #fff',
        '& *': {
            fontFamily: 'Ibm Plex Sans',
        },
    },
    creativityDropDown: {
        height: '35px',
        '& *': {
            fontFamily: 'Ibm Plex Sans',
        },
    },
    modelDropDown: {
        height: '35px',
        '& *': {
            fontFamily: 'Ibm Plex Sans',
        },
    },
    namefield: {
        height: '35px',
        ...shorthands.border('1px', 'solid', 'var(--neutral-midnight-70)'),
        '& *': {
            fontFamily: 'Ibm Plex Sans',
        },
    },
});
