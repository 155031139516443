import { makeStyles, shorthands } from '@fluentui/react-components';

export const useCreationCompClasses = makeStyles({
    creativityCard: {
        width: '100%',
        ...shorthands.borderRadius('4px'),
        ...shorthands.padding('24px'),
        ...shorthands.transition('all', '300ms', 'ease-in-out'),

        ':hover': {
            cursor: 'pointer',
        },
    },

    summarySegment: {
        ...shorthands.borderBottom('1px', 'solid', 'var(--neutral-midnight-60)'),
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('8px'),

        '@media (max-width: 1500px)': {
            '& p': {
                maxHeight: '50px',
                overflowY: 'auto',
            },
        },
        '& p': {
            maxHeight: '100px',
            overflowY: 'auto',
        },
    },

    summaryInfoCard: {
        backgroundColor: 'var(--neutral-midnight-50)',
        ...shorthands.borderRadius('4px'),
        ...shorthands.border('1px', 'solid', 'var(--neutral-midnight-70)'),
        ...shorthands.padding('10px', '12px'),
        ...shorthands.gap('0px'),
        display: 'flex',

        '& *': {
            ...shorthands.margin('0'),
            lineHeight: '22px',
        },
    },

    promptSummaryContainer: {
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: 'var(--neutral-midnight-70) #fff',
    },
});
