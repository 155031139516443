import { useMsal } from '@azure/msal-react';

import { GPTServiceId } from '../../components/utils/GPTModels';
import { IAssistant } from '../../libs/models/Assistants';
import { useAppDispatch, useAssistantsStatusSelector } from '../../redux/app/hooks';
import {
    addAssistant,
    deleteAssistant,
    editAssistant,
    setAssistants,
    setAssistantsError,
    setAssistantsStatus,
} from '../../redux/features/assistants/assistantsSlice';
import { EStatus } from '../../redux/features/conversations/ConversationsState';
import { AuthHelper } from '../auth/AuthHelper';
import { AssistantService } from '../services/AssistantService';

export const useAssistants = (name = '') => {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_SHOW_LOGS === 'true') {
        console.log(name);
    }
    const { instance, inProgress } = useMsal();
    const dispatch = useAppDispatch();
    const assistantsStatus = useAssistantsStatusSelector();
    const assistantService = AssistantService.getInstance();

    const createAssistantAsync = async (
        title: string,
        systemDescription: string,
        responseTemperature: number,
        modelServiceId: GPTServiceId,
    ) => {
        try {
            return await assistantService
                .createAssistantAsync(
                    title,
                    systemDescription,
                    responseTemperature,
                    modelServiceId,
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                )
                .then((ass) => {
                    dispatch(addAssistant(ass));
                    return ass;
                });
        } catch (err) {
            throw err;
        }
    };

    const editAssistantAsync = async (
        title: string,
        systemDescription: string,
        responseTemperature: number,
        id: string,
        active: boolean,
        modelServiceId: GPTServiceId,
    ) => {
        try {
            return await assistantService
                .editAssistantAsync(
                    title,
                    systemDescription,
                    responseTemperature,
                    id,
                    active,
                    modelServiceId,
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                )
                .then((ass) => {
                    dispatch(editAssistant(ass));
                    return ass;
                });
        } catch (err) {
            throw err;
        }
    };

    const getAllAssistantsAsync = async () => {
        if (assistantsStatus === EStatus.Loading) return null;
        dispatch(setAssistantsStatus(EStatus.Loading));
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const assistants = await assistantService.getAllAssistantsAsync(accessToken);
            const loadedAssistants: Record<string, IAssistant> = {};
            for (const assistant of assistants) {
                loadedAssistants[assistant.id] = assistant;
            }
            dispatch(setAssistants(loadedAssistants));
            dispatch(setAssistantsStatus(EStatus.Succeeded));
            dispatch(setAssistantsError(null));
            return loadedAssistants;
        } catch (error) {
            dispatch(setAssistantsStatus(EStatus.Failed));
        }
        return null;
    };

    const deleteAssistantAsync = async (id: string) => {
        try {
            await assistantService.deleteAssistantAsync(id, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
            dispatch(deleteAssistant(id));
        } catch (error) {
            console.error('Error occured while trying to delete assistant with id: ' + id);
        }
    };

    return {
        createAssistantAsync,
        getAllAssistantsAsync,
        deleteAssistantAsync,
        editAssistantAsync,
    };
};
