import { Icon } from '@iconify/react';
import SlDetails from '@shoelace-style/shoelace/dist/react/details';
import SlRadio from '@shoelace-style/shoelace/dist/react/radio';
import SlRadioGroup from '@shoelace-style/shoelace/dist/react/radio-group';
import { FC } from 'react';
import detailsStyles from './DetailsDropdown.module.scss';

interface DetailsDropdownProps {
    creativity: string;
    model: string;
    handleCreativityChange: (event: CustomEvent) => void;
    handleModelChange: (event: CustomEvent) => void;
}

export const DetailsDropdown: FC<DetailsDropdownProps> = ({
    creativity,
    model,
    handleCreativityChange,
    handleModelChange,
}) => {
    return (
        <SlDetails className={detailsStyles['details-overlay']}>
            <div slot="summary" className={detailsStyles['summary-slot']}>
                <div className={detailsStyles['icon-container']}>
                    <Icon icon="lets-icons:filter-big" width="16px" height="16px" />
                </div>
                <p>{model + ' / ' + creativity}</p>
            </div>
            <div className={detailsStyles['radio-container']}>
                <div className={detailsStyles['radio-column']}>
                    <SlRadioGroup
                        label="Creativity"
                        defaultValue="Balanced"
                        onSlChange={handleCreativityChange}
                        className={detailsStyles['radio-group']}
                    >
                        <SlRadio
                            className={
                                detailsStyles[creativity === 'Precise' ? 'custom-radio-selected' : 'custom-radio']
                            }
                            value="Precise"
                        >
                            <div className={detailsStyles['radio-content']}>
                                <b>More precise -</b> For factual tasks.
                            </div>
                            <Icon icon="lets-icons:check-fill" className={detailsStyles['radio-icon']} />
                        </SlRadio>

                        <SlRadio
                            className={
                                detailsStyles[creativity === 'Balanced' ? 'custom-radio-selected' : 'custom-radio']
                            }
                            value="Balanced"
                        >
                            <div className={detailsStyles['radio-content']}>
                                <b>More balanced -</b> For general use.
                            </div>
                            <Icon icon="lets-icons:check-fill" className={detailsStyles['radio-icon']} />
                        </SlRadio>
                        <SlRadio
                            className={
                                detailsStyles[creativity === 'Creative' ? 'custom-radio-selected' : 'custom-radio']
                            }
                            value="Creative"
                        >
                            <div className={detailsStyles['radio-content']}>
                                <b>More creative -</b> For innovative tasks.
                            </div>
                            <Icon icon="lets-icons:check-fill" className={detailsStyles['radio-icon']} />
                        </SlRadio>
                    </SlRadioGroup>
                </div>
                <div className={detailsStyles['radio-column']}>
                    <SlRadioGroup
                        label="Model"
                        defaultValue="GPT-4o"
                        onSlChange={handleModelChange}
                        className={detailsStyles['radio-group']}
                    >
                        <SlRadio
                            className={
                                detailsStyles[model === 'GPT-4o mini' ? 'custom-radio-selected' : 'custom-radio']
                            }
                            value="GPT-4o mini"
                        >
                            <div className={detailsStyles['radio-content']}>
                                <b>GPT-4o mini -</b> Fast, efficient, cost effective
                            </div>
                            <Icon icon="lets-icons:check-fill" className={detailsStyles['radio-icon']} />
                        </SlRadio>
                        <SlRadio
                            className={detailsStyles[model === 'GPT-4o' ? 'custom-radio-selected' : 'custom-radio']}
                            value="GPT-4o"
                        >
                            <div className={detailsStyles['radio-content']}>
                                <b>GPT-4o -</b> Smart, powerful, accurate
                            </div>
                            <Icon icon="lets-icons:check-fill" className={detailsStyles['radio-icon']} />
                        </SlRadio>
                    </SlRadioGroup>
                </div>
            </div>
        </SlDetails>
    );
};
