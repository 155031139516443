import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewStates } from '../../../components/views/ViewStates';
import { initialState, NavigationState } from './NavigationState';

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setNavigation: (state: NavigationState, action: PayloadAction<ViewStates>) => {
            state.navigation = action.payload;
        },
    },
});

export const { setNavigation } = navigationSlice.actions;

export default navigationSlice.reducer;
