import { makeStyles, shorthands } from '@fluentui/react-components';

export const usePopUpClasses = makeStyles({
    root: {
        width: '548px',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('8px'),
    },
    headerwrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    content: {
        fontFamily: 'IBM Plex Sans, sans serif',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    closebutton: {
        cursor: 'pointer',
    },
});
