export const functionalTabs = [
    { panelName: 'prompt', title: 'Create a prompt' },
    { panelName: 'creativity', title: 'Creativity' },
    { panelName: 'model', title: 'Model' },
    { panelName: 'name', title: 'Name' },
    { panelName: 'summary', title: 'Summary' },
];

interface INeighborPanels {
    previous: string;
    next: string;
}

export const neighborsTabsNames: Record<string, INeighborPanels> = functionalTabs.reduce(
    (acc, { panelName }, index) => ({
        ...acc,
        [panelName]: {
            previous: index ? functionalTabs[index - 1].panelName : '',
            next: index + 1 < functionalTabs.length ? functionalTabs[index + 1].panelName : '',
        },
    }),
    {},
);
