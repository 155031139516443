import { IAssistant } from '../../../libs/models/Assistants';
import { EStatus } from '../conversations/ConversationsState';

export interface AssistantsState {
    assistants: Record<string, IAssistant>;
    status: EStatus;
    error: string | null;
}

export const initialState: AssistantsState = {
    assistants: {},
    status: EStatus.Idle,
    error: null,
};
