import { useLocation, useNavigate, useResolvedPath } from 'react-router-dom';

import { Constants } from '../../Constants';

const { routes } = Constants;

export const useGoPage = () => {
    const { pathname } = useLocation();
    const { pathname: callerPath } = useResolvedPath(`../`);

    const navigate = useNavigate();

    const goStartPage = () => {
        navigate(routes.start);
    };

    const goTutorialPage = () => {
        const tutorialPath = `${pathname}${routes.tutorial}`.replace('//', '/');
        navigate(tutorialPath);
    };

    const goCreateAssistant = () => {
        navigate(routes.createAssistant);
    };
    const goEditAssistant = (assistantId: string) => {
        navigate(`${routes.editAssistant}/${assistantId}`);
    };
    const goManageAssistant = () => {
        navigate(routes.manageAssistant);
    };

    const goChat = (id: string) => {
        navigate(`${routes.chat}/${id}`);
    };

    const goBackToCaller = () => {
        navigate(callerPath);
    };

    const getTutorialSlidePath = (slideIndex: number) =>
        `${callerPath + routes.tutorial}/${slideIndex}`.replace('//', '/');

    const goTutorialForward = (currentSlideIndex: number) => {
        navigate(getTutorialSlidePath(currentSlideIndex + 2));
    };
    const goTutorialBackward = (currentSlideIndex: number) => {
        navigate(getTutorialSlidePath(currentSlideIndex));
    };

    const isAssistantCreateRoute = pathname.includes(Constants.routes.createAssistant);
    const isAssistantEditRoute = pathname.includes(Constants.routes.editAssistant);
    const isStartPageRoute = pathname === Constants.routes.start;

    return {
        goStartPage,
        goTutorialPage,
        goCreateAssistant,
        goEditAssistant,
        goManageAssistant,
        goChat,
        goBackToCaller,
        goTutorialForward,
        goTutorialBackward,
        isAssistantCreateRoute,
        isAssistantEditRoute,
        isStartPageRoute,
    };
};
