import { Button, Field, Textarea, Tooltip } from '@fluentui/react-components';
import { Icon } from '@iconify/react';
import SlTabGroup from '@shoelace-style/shoelace/dist/components/tab-group/tab-group';
import { FC } from 'react';
import { useAHClasses } from '../../../styles/assistantHub/assHubElements';
import { useButtonClasses } from '../../../styles/global/button';
import { useInputClasses } from '../../../styles/global/textArea';
import './tabPageWrappers.scss';
import { neighborsTabsNames } from './helper';

interface NameTabProps {
    assistantName: string;
    tabGroupRef: React.RefObject<SlTabGroup>;
    setName: (assistantName: string) => void;
    currentTabName: string;
}

const NameTab: FC<NameTabProps> = ({ assistantName, tabGroupRef, setName, currentTabName }) => {
    const { previous, next } = neighborsTabsNames[currentTabName];

    const onBack = () => {
        tabGroupRef.current?.show(previous);
    };

    const onNext = () => {
        tabGroupRef.current?.show(next);
    };

    const onChange = (_ev: any, data: { value: string }) => {
        setName(data.value);
    };

    const inputClasses: Record<'smallInput', string> = useInputClasses();
    const buttonClasses = useButtonClasses();
    const AHClasses = useAHClasses();

    return (
        <div className="tab-wrapper">
            <div className="content-wrapper">
                <div style={{ marginBottom: '24px' }}>
                    <div className="header-wrapper" style={{ marginBottom: '16px' }}>
                        <h2 style={{ margin: '0' }}>Give your assistant a name</h2>
                        <Tooltip
                            withArrow
                            content={'Choose a unique name that sets this assistant apart from others.'}
                            relationship={'description'}
                            aria-label="assistant name information"
                        >
                            <Icon
                                icon="lets-icons:info-light"
                                style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                            />
                        </Tooltip>
                    </div>
                    <p className={AHClasses.description} style={{ margin: 0 }}>
                        Pick a name that reflects your assistant&apos;s main task.
                    </p>
                    <Field hint="e.g.: Userstory Sally, Danny the Designer" style={{ marginTop: '16px' }}>
                        <Textarea
                            value={assistantName}
                            onChange={onChange}
                            className={inputClasses.smallInput}
                            placeholder="Enter text"
                            maxLength={30}
                        />
                    </Field>
                </div>
                <div>
                    <div className="button-wrapper">
                        <Button className={buttonClasses.secondaryButtonLarge} onClick={onBack}>
                            Back
                        </Button>
                        <Button className={buttonClasses.primaryButtonLarge} onClick={onNext}>
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NameTab;
