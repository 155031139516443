import { Button, Dialog, DialogActions, DialogBody, DialogSurface } from '@fluentui/react-components';
import { useState } from 'react';
import { useButtonClasses } from '../../styles/global/button';
import { useTutorialModalStyles } from '../../styles/tutorialModal';

interface OnboardingModalProps {
    isOpen: boolean;
    onClose: (open: boolean) => void;
    bodies: JSX.Element[];
    images: string[];
    slideNr: number;
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({ isOpen, onClose, bodies, images, slideNr = 0 }) => {
    const styles = useTutorialModalStyles();
    const buttonclasses = useButtonClasses();

    const [index, setIndex] = useState(slideNr);

    const dialogBody =
        images[index] != '' ? (
            <div className={styles.bodyContainer}>
                <div>{bodies[index]}</div>
                <img src={'/' + images[index]} style={{ width: '260px', height: '260px' }} alt="abstract image" />
            </div>
        ) : (
            <DialogBody>{bodies[index]}</DialogBody>
        );

    const lastSlideBtn =
        index == bodies.length - 1 ? (
            <Button
                onClick={() => {
                    onClose(true);
                }}
                className={buttonclasses.primaryButtonLarge}
            >
                Close
            </Button>
        ) : (
            <Button
                className={buttonclasses.primaryButtonLarge}
                onClick={() => {
                    setIndex(index + 1);
                }}
            >
                Continue
            </Button>
        );

    const dialogActions = (
        <DialogActions
            aria-label="Actions bar"
            style={{ alignItems: 'center', justifyContent: 'right', display: 'flex', padding: '12px 24px' }}
        >
            {index !== 0 && (
                <Button
                    onClick={() => {
                        setIndex(index - 1);
                    }}
                    className={buttonclasses.secondaryButtonLarge}
                >
                    Back
                </Button>
            )}
            {lastSlideBtn}
        </DialogActions>
    );

    const dialogTitle = (
        <div style={{ marginBottom: '8px' }}>
            <div
                style={{
                    height: '20px',
                    width: 'calc(100% - 48px)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '16px',
                    margin: '16px 24px',
                }}
            >
                {bodies.map((_, i) => (
                    <div
                        key={`modalProgress${i}`}
                        style={{
                            width: `100%`,
                            height: '8px',
                            backgroundColor: index > i - 1 ? '#37E17B' : '#EDEDED',
                            borderRadius: '1px',
                        }}
                    />
                ))}
            </div>
            <div style={{ height: '1px', width: '100%', backgroundColor: 'rgba(0.2,0,0,0.1)' }} />
        </div>
    );

    return (
        <Dialog
            modalType="modal"
            open={isOpen}
            onOpenChange={(_, data) => {
                setIndex(0);
                onClose(data.open);
            }}
        >
            <DialogSurface
                aria-label="Tutorial"
                style={{ width: '730px', maxWidth: '730px', height: 'fit-content', padding: 0 }}
            >
                {dialogTitle}
                <div style={{ marginRight: '24px' }}>
                    {dialogBody}
                    <div style={{ height: '1px', width: '100%', backgroundColor: 'rgba(0.2,0,0,0.1)' }} />
                </div>
                {dialogActions}
            </DialogSurface>
        </Dialog>
    );
};

export default OnboardingModal;
