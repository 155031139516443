import { makeStyles, shorthands } from '@fluentui/react-components';

export const useButtonClasses = makeStyles({
    primaryButton: {
        ...shorthands.border('none'),
        ...shorthands.padding('5px', '12px'),
        ...shorthands.borderRadius('4px'),
        backgroundColor: 'var(--primary-sea-at-night-100)',
        color: 'white',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '22px !important',
        display: 'flex',
        justifyContent: 'center',
        ...shorthands.gap('4px'),
        minWidth: '70px',

        ':hover': {
            backgroundColor: 'var(--extended-grass)',
            color: 'black',
        },

        ':active': {
            backgroundColor: 'var(--extended-grass-in-bright-light) !important',
            color: 'black',
        },
        ':disabled, [disabled]': {
            backgroundColor: 'var(--neutral-midnight-60) !important',
            color: 'var(--neutral-midnight-80)',
            pointerEvents: 'none',
        },
    },
    secondaryButton: {
        ...shorthands.border('1px', 'solid', 'var(--neutral-midnight-70)'),
        ...shorthands.padding('5px', '12px'),
        ...shorthands.borderRadius('4px'),
        backgroundColor: 'transparent',
        color: 'black',
        fontSize: '14px',
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'center',
        ...shorthands.gap('4px'),
        minWidth: '70px',

        ':hover': {
            ...shorthands.border('1px', 'solid', 'var(--extended-grass)'),
            backgroundColor: 'var(--neutral-midnight-60)',
            color: 'black',
        },

        ':active': {
            ...shorthands.border('1px', 'solid', 'var(--extended-grass)'),
            backgroundColor: 'var(--extended-grass-in-bright-light) !important',
            color: 'black',
        },
        ':disabled, [disabled]': {
            backgroundColor: 'var(--neutral-midnight-60) !important',
            color: 'var(--neutral-midnight-80)',
            ...shorthands.border('1px', 'solid', 'var(--neutral-midnight-80)'),
            pointerEvents: 'none',
        },
    },
    primaryButtonLarge: {
        ...shorthands.border('none'),
        ...shorthands.padding('8px', '16px'),
        ...shorthands.borderRadius('4px'),
        backgroundColor: 'var(--primary-sea-at-night-100)',
        color: 'white',
        fontSize: '14px',
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'center',
        ...shorthands.gap('4px'),
        minWidth: '90px',

        ':hover': {
            backgroundColor: 'var(--extended-grass)',
            color: 'black',
        },

        ':active': {
            backgroundColor: 'var(--extended-grass-in-bright-light) !important',
            color: 'black',
        },
        ':disabled, [disabled]': {
            backgroundColor: 'var(--neutral-midnight-60) !important',
            color: 'var(--neutral-midnight-80)',
            pointerEvents: 'none',
        },
    },
    secondaryButtonLarge: {
        ...shorthands.border('1px', 'solid', 'var(--neutral-midnight-70)'),
        ...shorthands.padding('8px', '16px'),
        ...shorthands.borderRadius('4px'),
        backgroundColor: 'transparent',
        color: 'black',
        fontSize: '14px',
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'center',
        ...shorthands.gap('4px'),
        minWidth: '90px',

        ':hover': {
            ...shorthands.border('1px', 'solid', 'var(--extended-grass)'),
            backgroundColor: 'var(--neutral-midnight-60)',
            color: 'black',
        },

        ':active': {
            ...shorthands.border('1px', 'solid', 'var(--extended-grass)'),
            backgroundColor: 'var(--extended-grass-in-bright-light) !important',
            color: 'black',
        },
        ':disabled, [disabled]': {
            backgroundColor: 'var(--neutral-midnight-60) !important',
            color: 'var(--neutral-midnight-80)',
            ...shorthands.border('1px', 'solid', 'var(--neutral-midnight-80)'),
            pointerEvents: 'none',
        },
    },
    navButton: {
        paddingBottom: '11px',
        paddingTop: '11px',
    },
});
