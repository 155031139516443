import React from 'react';
import { Outlet } from 'react-router-dom';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

import { useGoPage } from '../../libs/hooks/useRouterNavigate';
import { IAssistant } from '../../libs/models/Assistants';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { CreatePAButton, PAItem } from '../assistant-hub/PAButton';
import { DefaultButton as CreateFirstAssistantButton } from '../global/Buttons';

import classes from './AssistantHub.module.scss';

const assistantSorterByDate = (a: IAssistant, b: IAssistant) => {
    if (!a.createdOn || !b.createdOn || a.createdOn === b.createdOn) return 0;
    return a.createdOn > b.createdOn ? -1 : 1;
};

const AssistantHub: React.FC = () => {
    const { goCreateAssistant } = useGoPage();
    const { assistants } = useAppSelector((state: RootState) => state.assistants);
    const { showSideBar } = useAppSelector((state: RootState) => state.app);

    const assistantsIds = Object.values(assistants)
        .sort(assistantSorterByDate)
        .map(({ id }) => id);

    const isAssistantsListEmpty = !assistantsIds.length;

    return (
        <div
            aria-label="Manage assistants"
            className={`${classes.container} ${showSideBar ? '' : classes['sidebar-hidden']}`}
        >
            <div className={classes.column}>
                <h1 className={classes.title}>Manage assistants</h1>
                <div aria-label="Assistant hub main view" className={classes.assistants}>
                    <h2 className={classes.title2}>Personal Assistants</h2>
                    {isAssistantsListEmpty ? (
                        <div className={classes['create-1st-assistant']}>
                            <div className={classes['create-1st-assistant-icon-container']}>
                                <img
                                    src="/AI_green.svg"
                                    className={classes['create-1st-assistant-icon']}
                                    alt="Robot face with green underline"
                                />
                            </div>
                            <h2 className={classes['create-1st-assistant-header']}>Create your first assistant</h2>
                            <p className={classes['create-1st-assistant-description']}>
                                Let it take care of the chores that steal your time. You can manage your assistants here
                                anytime.
                            </p>

                            <CreateFirstAssistantButton
                                onClick={goCreateAssistant}
                                className={classes['create-1st-assistant-button']}
                            >
                                <SlIcon slot="prefix" name="plus-circle"></SlIcon>
                                Create assistant
                            </CreateFirstAssistantButton>
                        </div>
                    ) : (
                        <>
                            <CreatePAButton />
                            <p className={classes.description}>Manage (hide, edit or delete) your assistants here.</p>
                            <div aria-label="Personal assistants" className={classes['personal-assistants']}>
                                {assistantsIds.map((id) => (
                                    <PAItem key={id} assistantId={id} />
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Outlet />
        </div>
    );
};

export default AssistantHub;
