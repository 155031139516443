import SlDialog, { SlRequestCloseEvent } from '@shoelace-style/shoelace/dist/react/dialog';
import React, { useEffect, useState } from 'react';
import dialogClasses from './UpdateDialog.module.scss';

const UpdateDialog: React.FC<{ cutoffDate: string; header: string }> = ({ cutoffDate, header }) => {
    // Description: Dialog will be open if user has not closed it once and the current date is before the cutoff date
    // This will ensure user only sees the dialog once, and will never see it after the cutoff date
    const today = new Date(); // Format: yyyy-mm-dd
    const [isDoNotShowAgain, setIsDoNotShowAgain] = useState(true);

    // Prevent the dialog from closing when the user clicks outside of it (i.e. on the overlay)
    const handleRequestClose: (e: SlRequestCloseEvent) => void = (event) => {
        if (event.detail.source === 'overlay') {
            event.preventDefault();
        }
    };

    // Set the 'isDoNotShowAgain' key in local cache when closing the dialog
    const handleClose = () => {
        localStorage.setItem(header + '-isDoNotShowAgain', 'true');
        setIsDoNotShowAgain(true);
    };

    // Fetch the value of the 'isDoNotShowAgain' key from local cache
    useEffect(() => {
        const isDoNotShowAgainCache = localStorage.getItem(header + '-isDoNotShowAgain');
        if (isDoNotShowAgainCache == 'true') {
            setIsDoNotShowAgain(true);
        } else {
            setIsDoNotShowAgain(false);
        }
    }, []);

    return (
        <SlDialog
            open={today < new Date(cutoffDate) && !isDoNotShowAgain}
            label={header}
            onSlRequestClose={handleRequestClose}
            onSlAfterHide={handleClose}
            className={dialogClasses['update-dialog']}
        >
            <img src="/update-images/introducing-4o-mini.png" alt="GPT-4o mini" />
            <div className={dialogClasses['update-content']}>
                GPT-3.5 is now being replaced by GPT-4o mini.
                <ul>
                    <li>Significantly reduces costs compared to GPT-3.5.</li>
                    <li>Outperforms GPT-3.5 in text, coding, and multimodal tasks.</li>
                    <li>
                        Can handle more extensive conversations or documents, making it easier to work on bigger
                        projects without losing context.
                    </li>
                    <li>
                        Capable of generating longer, more detailed responses, which is useful for complex questions or
                        tasks.
                    </li>
                    <li>Has a knowledge base up to October 2023.</li>
                    <li>
                        Read more on{' '}
                        <a
                            href="https://openai.com/index/gpt-4o-mini-advancing-cost-efficient-intelligence/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            GPT-4o mini
                        </a>
                    </li>
                </ul>
                GPT-4o mini will be the new default model on Sapience, but you can always switch to GPT-4o when you need
                more power. <b>You can continue to use your old chats and assistants as normal.</b>
            </div>
        </SlDialog>
    );
};

export default UpdateDialog;
