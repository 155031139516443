import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useStepwiseClasses = makeStyles({
    root: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingHorizontalM),
    },
    accordionItem: {
        width: '95%',
    },
    header: {
        width: '100%',
        /* Styles for the button within the header */
        '& button': {
            alignItems: 'flex-start',
            minHeight: '-webkit-fill-available',
            paddingLeft: tokens.spacingHorizontalNone,
        },
    },
});