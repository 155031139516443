import { makeStyles, shorthands } from '@fluentui/react-components';

export const useChatStatusClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...shorthands.gap('8px'),
    },
});
