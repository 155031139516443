import { makeStyles, shorthands } from '@fluentui/react-components';

export const useTutorialModalStyles = makeStyles({
    dialog: {
        maxWidth: 'fit-content',
        width: '80vw',
    },
    imageDiv: {
        width: '35%',
    },
    image: {
        height: '600px',
        maxHeight: '60vh',
        width: 'auto',
        maxWidth: '100%',
        objectFit: 'contain',
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...shorthands.padding('32px'),
        ...shorthands.gap('24px'),
    },
    skipDiv: {
        cursor: 'pointer',
        transform: 'translate(0%,15%)',
        textAlign: 'left',
        marginRight: 'auto',
    },
    leftAlignItemLarger: {
        textAlign: 'left',
        marginRight: 'auto',
        fontSize: '1.5em',
    },
    rightAlignItem: {
        textAlign: 'right',
    },
    rightAlignItemClickable: {
        textAlign: 'right',
        cursor: 'pointer',
    },
    rightAlignBlueButton: {
        textAlign: 'right',
        backgroundColor: 'rgba(0,0,100,255)',
        color: 'rgba(255,255,255,255)',
    },
    leftRightAlignContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftRightAlignContainerMargin: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '10px',
    },
    greyDiv: {
        backgroundColor: 'rgba(218, 223, 225, 255)', // Iron https://www.flatuicolorpicker.com/grey-rgba-color-model/
        textAlign: 'left',
        fontSize: '14px',
        marginRight: 'auto',
        display: 'inline-block',
        ...shorthands.borderRadius('10px'),
        ...shorthands.padding('4px', '8px'),
        marginBottom: '7px',
    },
    title: {
        fontSize: '32px',
        fontFamily: 'neue machina',
        fontWeight: '500',
    },
    paragraph: {
        fontSize: '16px',
        fontFamily: 'ibm plex sans',
    },
});
