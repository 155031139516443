import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useChatInputClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '105em',
        ...shorthands.margin(tokens.spacingVerticalNone, tokens.spacingHorizontalM),
    },
    typingIndicator: {
        maxHeight: '28px',
    },
    content: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    input: {
        maxHeight: '60vh',
        height: 'auto',
        width: '100%',
        '& *': {
            fontFamily: 'IBM Plex Sans',
            fontSize: '14px',
        },
    },
    textarea: {
        maxHeight: '40px',
        paddingTop: '16px',
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.padding('0', '40px', '40px', '0'),
        backgroundColor: 'red',
    },
    essentials: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: '1',
        height: '100%',
        alignItems: 'end',
    },
    functional: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '1',
        height: '100%',
        alignItems: 'end',
    },
    dragAndDrop: {
        ...shorthands.border(tokens.strokeWidthThick, ' solid', tokens.colorBrandStroke1),
        ...shorthands.padding('8px'),
        textAlign: 'center',
        backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorBrandForeground1,
        caretColor: 'transparent',
    },
    buttonIconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F9F9F9',
        ...shorthands.borderRadius('50%'),
        ...shorthands.padding('4px'),
        ...shorthands.transition('all', '0.3s'),
        '&:hover': {
            backgroundColor: '#85E29F',
        },
        '&:active': {
            backgroundColor: '#E1F6E0',
        },
        '&:focus': {
            backgroundColor: 'red',
        },
    },
});