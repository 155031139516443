import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStartPageClasses = makeStyles({
    startPageSection: {
        display: 'flex',
        fontFamily: 'IBM Plex Sans',
        flexDirection: 'row',
        alignItems: 'center',
        ...shorthands.padding('16px'),
        ...shorthands.borderRadius('4px'),
        ...shorthands.border('1px', 'solid', '#ededed'),
        ...shorthands.margin('auto'),
    },
    startPageCard: {
        color: '#FFFFFF',
        display: 'flex',
        fontFamily: 'IBM Plex Sans',
        fontSize: '18px',
        flexDirection: 'row',
        //height: '88px',
        //width: '671px',
        height: '10%',
        width: '100%',
        alignItems: 'center',
        paddingBottom: '4px',
        cursor: 'pointer',
        ...shorthands.transition('all', '300ms'),
        ...shorthands.borderRadius('4px'),
        ...shorthands.padding('16px'),
        ...shorthands.border('1px', 'solid', 'var(--Neutral-Stroke-Alpha-2-Rest, rgba(255, 255, 255, 0.20))'),
        zIndex: '1',
        '&:hover': {
            backgroundColor: 'rgba(55,225,123,0.2)',
            ...shorthands.border('1px', 'solid', 'var(--core-jade)'),
        },

        '& *': {
            fontFamily: 'IBM Plex Sans',
        },
    },
    // disabled startpagecard
    startPageCardDisabled: {
        color: '#FFFFFF',
        display: 'flex',
        fontFamily: 'IBM Plex Sans',
        fontSize: '18px',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: '4px',
        width: '44%',
        cursor: 'not-allowed',
        backgroundColor: '#F5F5F5',
        opacity: '0.5',
        ...shorthands.borderRadius('4px'),
        ...shorthands.padding('16px'),
        ...shorthands.border('1px', 'solid', '#ededed'),
    },
    startPageElipseUpperCornerBg: {
        width: '150vh',
        height: '150vh',
        backgroundImage: 'radial-gradient(50% 50% at 50% 50%, #37E17B 0%, rgba(55, 225, 123, 0.00) 100%)',
        opacity: '0.4',
        zIndex: '0',
        position: 'fixed',
        top: '-75vh',
        right: '-50vh',
    },
    startPageElipseLowerCornerBg: {
        width: '100vh',
        height: '100vh',
        backgroundImage: 'radial-gradient(50% 50% at 50% 50%, #9747FF 0%, rgba(151, 71, 255, 0.00) 100%)',
        opacity: '0.4',
        zIndex: '0',
        position: 'fixed',
        bottom: '-75vh',
        left: '25vh',
    },
    startPageCardImgText: {
        textAlign: 'left',
        fontFamily: 'IBM Plex Sans',
        display: 'flex',
        width: '90%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...shorthands.margin('16'),
    },
    startPageCardImg: {
        backgroundColor: 'rgba(0,0,0,0)',
        stroke: '#FFFFFF',
        fontFamily: 'IBM Plex Sans',
        marginRight: '16px',
        width: '38px',
        height: '38px',
        ...shorthands.borderRadius('4px'),
        ...shorthands.padding('5px'),
    },
    startPageText: {
        textAlign: 'left',
        fontFamily: 'IBM Plex Sans',
        marginRight: 'auto',
    },
    // This should be extracted into general styles or use already exisitng general style
    airaButton: {
        /*
        backgroundColor: '#000833',
        color: '#FFF',
        fontFamily: 'IBM Plex Sans',
        '&:hover': {
            backgroundColor: '#85E29F',
            color: '#000',
            fontFamily: 'IBM Plex Sans',
        },
        '&:active': {
            backgroundColor: '#E1F6E0',
            color: '#000',
            fontFamily: 'IBM Plex Sans',
        } /*
      '&:focus': {
        backgroundColor: '#E1F6E0',
        color: '#000',
        fontFamily: 'IBM Plex Sans',
      },*/
        /*,
        ...shorthands.transition('all', '300ms'),
        ...shorthands.border('none'),*/
    },
    startPageInput: {
        backgroundColor: 'white',
        ...shorthands.borderRadius('4px'),
        '& textarea': {
            ...shorthands.padding('20px', '60px', '0', '12px'),
        },
    },

    whoAmI: {
        color: '#85E29F',
        fontFamily: 'IBM Plex Sans',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        cursor: 'pointer',
        zIndex: '1',

        '&:hover': {
            color: 'white',
            ...shorthands.transition('all', '500ms'),
        },
    },
});
