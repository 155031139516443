import { FC } from 'react';

import styles from './ErrorPage.module.scss';
import { useActiveUserInfoSelector } from '../../redux/app/hooks';

const ErrorPage: FC = () => {
    const activeUser = useActiveUserInfoSelector();

    return (
        <div className={styles.container}>
            <div className={styles.startPageElipseUpperCornerBg} />
            <div className={styles.startPageElipseLowerCornerBg} />
            <div className={styles.wrapper}>
                <h1>Dear {activeUser ? activeUser.username : 'Guest'},</h1>
                <p>
                    I hope this message finds you well. It has come to my attention that the page you are currently
                    seeking does not yet exist. However, I would like to kindly suggest that you consider contributing
                    to its creation. Your valuable input and expertise would be greatly appreciated in bringing this
                    page to life.
                </p>
                <p>
                    By sharing your knowledge and insights, you have the opportunity to make a significant impact on the
                    development of this platform. Together, we can create a space where individuals can find the
                    information they seek and engage in meaningful discussions.
                </p>
                <p>
                    If you are interested in contributing, please feel free to reach out to our team or explore the
                    options available for content creation. We truly believe that your contributions will enhance the
                    overall value of this platform and benefit its users.
                </p>
                <p>
                    Thank you for considering this invitation, and we look forward to potentially collaborating with you
                    in the near future.
                </p>
                <p>Best regards, Sapience</p>
            </div>
        </div>
    );
};

export default ErrorPage;
