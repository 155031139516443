import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { SharedStyles } from "../../global/styles";

export const useChatHistoryDocumentClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(tokens.spacingVerticalM, 0),
    },
    card: {
        height: 'fit-content',
        width: '275px',
        backgroundColor: tokens.colorNeutralBackground3,
        ...shorthands.gap(0),
        ...shorthands.margin(tokens.spacingVerticalXXS, 0),
        ...shorthands.padding(tokens.spacingVerticalXXS, 0),
    },
    cardCaption: {
        color: tokens.colorNeutralForeground2,
    },
    cardHeader: {
        ...shorthands.margin(0, tokens.spacingHorizontalS),
    },
    cardHeaderText: {
        fontSize: 'small',
        fontWeight: '500',
    },
    cardHeaderTextContainer: {
        ...SharedStyles.overflowEllipsis,
    },
    footer: {
        float: 'right',
        fontSize: 'small',
        fontWeight: '500',
        color: tokens.colorNeutralForegroundDisabled,
    },
    icon: {
        height: '32px',
        width: '32px',
    },
    floatLeft: {
        float: 'left',
    },
});