import type { DropdownProps } from '@fluentui/react-components';
import { Button, Dropdown, Field, Input, Option, Textarea, Tooltip } from '@fluentui/react-components';
import { Icon } from '@iconify/react';
import { FC } from 'react';

import { Constants } from '../../../Constants';
import { useGoPage } from '../../../libs/hooks/useRouterNavigate';
import { useAppDispatch } from '../../../redux/app/hooks';
import { setNavigation } from '../../../redux/features/navigation/navigationSlice';
import { useCreationCompClasses } from '../../../styles/assistantCreation/creationComponents';
import { useCreationWrapperClasses } from '../../../styles/assistantCreation/creationWrappers';
import { useEditClasses } from '../../../styles/assistantCreation/editComponents';
import { useButtonClasses } from '../../../styles/global/button';
import { PopUpDialog } from '../../popups/PopUpDialog';
import { PopUpDialogNA } from '../../popups/PopUpDialogNoAction';
import { GPTServiceId } from '../../utils/GPTModels';
import { ViewStates } from '../../views/ViewStates';

const creativityOptions = [
    { value: 0.1, text: 'More precise - For factual tasks.' },
    { value: 0.5, text: 'More balanced - For general use.' },
    { value: 0.9, text: 'More creative - For innovative tasks.' },
];

const gptModelOptions = [
    {
        value: 'gpt4oMini',
        text: 'GPT-4o mini - Fast, efficient, cost effective.',
    },
    {
        value: 'gpt4o',
        text: 'GPT-4o - Smart, powerful, accurate.',
    },
];

interface EditTabProps {
    assistantName: string;
    workDescription: string;
    creativityLevel: number;
    modelName: string;
    setFunctions: {
        setWorkDescription: (x: string) => void;
        setCreativityLevel: (x: number) => void;
        setModelName: (x: GPTServiceId) => void;
        setName: (x: string) => void;
    };
    handleCreate: () => void;
    isLoading: boolean;
}

const EditTab: FC<EditTabProps> = ({
    assistantName,
    workDescription,
    creativityLevel,
    modelName,
    handleCreate,
    setFunctions,
    isLoading,
}) => {
    const dispatch = useAppDispatch();
    const { goStartPage } = useGoPage();
    // Handle work description
    const onChange = (_ev: any, data: { value: string }) => {
        setFunctions.setWorkDescription(data.value);
    };

    // Handle assistant name
    const onChangeName = (_ev: any, data: { value: string }) => {
        setFunctions.setName(data.value);
    };

    // Handle creativity
    const onOptionSelectCreativity: DropdownProps['onOptionSelect'] = (_, { optionValue }) => {
        setFunctions.setCreativityLevel(Number(optionValue));
    };

    // Handle model
    const onOptionSelectModel: DropdownProps['onOptionSelect'] = (_, { optionValue }) => {
        setFunctions.setModelName(optionValue as GPTServiceId);
    };

    const handleCancel = () => {
        dispatch(setNavigation(ViewStates.Exploring));
        goStartPage();
    };

    const buttonClasses = useButtonClasses();
    const wrapperclasses = useCreationWrapperClasses();
    const compclasses = useCreationCompClasses();
    const editClasses = useEditClasses();

    const PopUpController: FC = () => {
        if (assistantName.trim() == '' && workDescription.trim() == '') {
            return (
                <PopUpDialogNA
                    header="Ooops. Something is missing."
                    content="Your assistant needs a prompt and a name."
                    canHide={false}
                    onAction={handleCreate}
                >
                    <Button className={buttonClasses.primaryButtonLarge}>Save changes</Button>;
                </PopUpDialogNA>
            );
        } else if (assistantName.trim() == '') {
            return (
                <PopUpDialogNA
                    header="Ooops. Something is missing."
                    content="Your assistant needs a name."
                    canHide={false}
                    onAction={handleCreate}
                >
                    <Button className={buttonClasses.primaryButtonLarge}>Save changes</Button>;
                </PopUpDialogNA>
            );
        } else if (workDescription.trim() == '') {
            return (
                <PopUpDialogNA
                    header="Ooops. Something is missing."
                    content="Your assistant needs a prompt."
                    canHide={false}
                    onAction={handleCreate}
                >
                    <Button className={buttonClasses.primaryButtonLarge}>Save changes</Button>;
                </PopUpDialogNA>
            );
        } else {
            return (
                <PopUpDialog
                    header="New changes: New chat"
                    content="Changes only affect new chats going forward and won't impact previous chats. Start a new chat to see the new changes."
                    canHide={true}
                    onAction={handleCreate}
                    buttonText="OK"
                >
                    <Button disabled={isLoading} className={buttonClasses.primaryButtonLarge}>
                        Save changes
                    </Button>
                </PopUpDialog>
            );
        }
    };

    return (
        <div className={wrapperclasses.TabWrapper}>
            <div className={wrapperclasses.contentWrapper} style={{ marginTop: '24px' }}>
                <div>
                    <div className={compclasses.summarySegment} style={{ marginBottom: '16px', border: 'none' }}>
                        <div className={wrapperclasses.summaryHeaderWrapper}>
                            <h4>1. Prompt</h4>
                            <Tooltip
                                withArrow
                                content={
                                    'A prompt is a way to guide the conversation and provide specific instructions to get the desired response from your assistant.'
                                }
                                relationship={'description'}
                                aria-label="prompt tip"
                            >
                                <Icon
                                    icon="lets-icons:info-light"
                                    style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                                />
                            </Tooltip>
                        </div>
                        <Field>
                            <Textarea
                                value={workDescription}
                                onChange={onChange}
                                style={{ height: '75px', margin: '0' }}
                                placeholder="Enter prompt here"
                                resize="none"
                                className={editClasses.promptfield}
                            />
                        </Field>
                    </div>
                    <div className={compclasses.summarySegment} style={{ marginBottom: '16px', border: 'none' }}>
                        <div className={wrapperclasses.summaryHeaderWrapper}>
                            <h4>2. Creativity</h4>
                            <Tooltip
                                withArrow
                                content={
                                    'Adjusting the creativity affects how varied the response from the assistant will be'
                                }
                                relationship={'description'}
                                aria-label="creativity tip"
                            >
                                <Icon
                                    icon="lets-icons:info-light"
                                    style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                                />
                            </Tooltip>
                        </div>

                        <Dropdown
                            onOptionSelect={onOptionSelectCreativity}
                            value={
                                creativityOptions.find((o) => o.value === creativityLevel)?.text ??
                                String(creativityLevel)
                            }
                            selectedOptions={creativityOptions
                                .filter((o) => o.value === creativityLevel)
                                .map((o) => String(o.value))}
                            className={editClasses.creativityDropDown}
                        >
                            {creativityOptions.map(({ value, text }) => (
                                <Option key={`creativityKey${value}`} value={String(value)}>
                                    {text}
                                </Option>
                            ))}
                        </Dropdown>
                    </div>
                    <div className={compclasses.summarySegment} style={{ marginBottom: '16px', border: 'none' }}>
                        <div className={wrapperclasses.summaryHeaderWrapper}>
                            <h4>3. Model</h4>
                            <Tooltip
                                withArrow
                                content={'Model decision impacts assistant performance and task capabilities.'}
                                relationship={'description'}
                                aria-label="model selection tip"
                            >
                                <Icon
                                    icon="lets-icons:info-light"
                                    style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                                />
                            </Tooltip>
                        </div>
                        <Dropdown
                            onOptionSelect={onOptionSelectModel}
                            value={
                                gptModelOptions.find((o) => o.value === modelName)?.text ?? Constants.DEFAULT_MODEL_NAME
                            }
                            selectedOptions={gptModelOptions.map((o) => o.value).filter((value) => value === modelName)}
                            className={editClasses.modelDropDown}
                        >
                            {gptModelOptions.map(({ value, text }) => (
                                <Option key={`modelKey${value}`} value={String(value)}>
                                    {text}
                                </Option>
                            ))}
                        </Dropdown>
                    </div>
                    <div className={compclasses.summarySegment} style={{ marginBottom: '16px', border: 'none' }}>
                        <div className={wrapperclasses.summaryHeaderWrapper}>
                            <h4>4. Name</h4>
                            <Tooltip
                                withArrow
                                content={'Choose a unique name that sets this assistant apart from others.'}
                                relationship={'description'}
                                aria-label="assistant name information"
                            >
                                <Icon
                                    icon="lets-icons:info-light"
                                    style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                                />
                            </Tooltip>
                        </div>
                        <Input
                            value={assistantName}
                            onChange={onChangeName}
                            className={editClasses.namefield}
                            placeholder="Enter name"
                            maxLength={30}
                        />
                    </div>
                </div>
                <div>
                    <div className={wrapperclasses.buttonWrapper}>
                        <PopUpDialog
                            header={'Unsaved changes'}
                            content={'By exiting assistant creation, all progress will be lost.'}
                            buttonText={'Exit'}
                            onAction={handleCancel}
                            canHide={false}
                        >
                            <Button className={buttonClasses.secondaryButtonLarge}>Cancel</Button>
                        </PopUpDialog>
                        <PopUpController />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditTab;
