//import { Button } from '@fluentui/react-components';
import { useParams } from 'react-router-dom';

import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlDialog from '@shoelace-style/shoelace/dist/react/dialog';
import { useGoPage } from '../../libs/hooks/useRouterNavigate';
//import { useTutorialModalStyles } from '../../styles/tutorialModal';
import buttonStyles from '../global/buttons.module.scss';
import tutorialStyles from './TutorialModal.module.scss';
import { tutorialImages } from './TutorialModalData';

const TutorialModal: React.FC = () => {
    const { goBackToCaller, goTutorialForward, goTutorialBackward } = useGoPage();

    const { slideNr } = useParams();

    const getImageSrc = (i: number) => '/' + tutorialImages[i];

    const numberOfSlides = tutorialImages.length;
    //const styles = useTutorialModalStyles();

    let index = Number(slideNr) - 1;
    if (index >= numberOfSlides) index = numberOfSlides - 1;
    const isLastSlide = Number(slideNr) >= numberOfSlides;

    const moveForward = () => {
        goTutorialForward(index);
    };
    const moveBackward = () => {
        goTutorialBackward(index);
    };

    return (
        <SlDialog
            open
            onSlAfterHide={goBackToCaller}
            label="Sapience essentials"
            className={tutorialStyles['tutorial-dialog']}
        >
            <div className={tutorialStyles['step-wrapper']}>
                <div className={tutorialStyles['step-counter']}>
                    <strong>Page {index + 1}</strong>/{numberOfSlides}
                </div>
            </div>

            <img
                src={getImageSrc(index)}
                className={tutorialStyles['tutorial-image']}
                alt="Image illustrating contents of instructions"
            />

            <div className={tutorialStyles['actions-wrapper']}>
                <SlButton onClick={moveBackward} disabled={!index} className={buttonStyles['secondary-large']}>
                    Back
                </SlButton>
                {isLastSlide ? (
                    <SlButton onClick={goBackToCaller} className={buttonStyles['primary-large']}>
                        Done
                    </SlButton>
                ) : (
                    <SlButton onClick={moveForward} className={buttonStyles['primary-large']}>
                        Next
                    </SlButton>
                )}
            </div>
        </SlDialog>
    );
};

export default TutorialModal;
