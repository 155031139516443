import { makeStyles, shorthands } from '@fluentui/react-components';

export const useCreationWrapperClasses = makeStyles({
    TabWrapper: {
        width: '100%',
        height: 'calc(100% - 48px)', //Account for padding
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        ...shorthands.borderRadius('4px'),
        ...shorthands.overflow('auto'),
        scrollbarWidth: 'thin',
        scrollbarColor: 'var(--neutral-midnight-70) #fff',
    },
    contentWrapper: {
        ...shorthands.margin('5%', 'auto'),
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '1000px',
        maxHeight: '500px',

        ['@media (max-width: 1500px)']: {
            ...shorthands.margin('2%', 'auto'),
        },
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('8px'),
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...shorthands.gap('8px'),
    },
    cardsContainer: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('16px'),
        width: '100%',
    },
    modelCardHeaderWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    summaryHeaderWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...shorthands.gap('8px'),
    },
    promptGeneratorWrapper: {
        ...shorthands.padding('16px'),
        ...shorthands.borderRadius('4px'),
        ...shorthands.border('1px', 'solid', 'var(--extended-grass)'),
        backgroundColor: 'rgba(133, 226, 159, 0.05)',
    },
});
