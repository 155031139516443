import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useDocumentsTabClasses = makeStyles({
    functional: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.margin('0', '0', tokens.spacingVerticalS, '0'),
    },
    pageheader: {
        fontFamily: 'IBM Plex Sans',
        fontSize: '20px',
        fontWeight: '600',
        ...shorthands.margin('0'),
    },
    uploadButton: {
        ...shorthands.margin('0', tokens.spacingHorizontalS, '0', '0'),
        width: '100%',
        ...shorthands.padding('16px 0'),
        fontSize: '12px',
        fontWeight: '600',
        fontFamily: 'IBM Plex Sans',
        ...shorthands.border('1px', 'dashed', '#85E29F'),
        ...shorthands.padding('16px', '0'),
        marginRight: '0',
        color: 'black',

        '&:hover': {
            backgroundColor: '#e1f6e0',
            color: 'black',
            '&:disabled': {
                backgroundColor: '#F9F9F9',
                color: 'grey',
            },
        },
        '&:disabled': {
            backgroundColor: '#F9F9F9',
            color: 'grey',
        },
    },
    vectorDatabase: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        marginLeft: 'auto',
        ...shorthands.gap(tokens.spacingHorizontalSNudge),
    },
    table: {
        backgroundColor: 'tokens.colorNeutralBackground1,',
        fontFamily: 'IBM Plex Sans',
        fontSize: '14px',
        color: 'black',
        '& .table-header': {
            backgroundColor: '#EDEDED',
            ...shorthands.padding('16px'),
            '& *': {
                fontWeight: '600',
                color: 'black',
                ...shorthands.borderBottom('none'),
            },
            ...shorthands.padding('8px', '16px'),
        },
        '& td:first-of-type': {
            fontWeight: '600',
        },
    },
    tableHeader: {
        backgroundColor: 'red',
        fontWeight: tokens.fontSizeBase600,
    },
    progressBox: {
        ...shorthands.padding('4px'),
        ...shorthands.borderRadius('4px'),
        ...shorthands.border('1.8px', 'solid', '#37E17B'),
        width: 'fit-content',
        fontWeight: '600',
        fontSize: '12px',
        '& .success': {
            ...shorthands.borderColor('#37E17B'),
        },
        '& .loading': {
            ...shorthands.borderColor('#CDD3D8'),
        },
    },

    uploadIconWrapper: {
        width: '32px',
        height: '32px',
        ...shorthands.borderRadius('50%'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
