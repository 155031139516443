import SlSkeleton from '@shoelace-style/shoelace/dist/react/skeleton';

import skeletonStyles from './chatListItemSkeleton.module.scss';

export const ChatListItemSkeleton = () => {
    return (
        <div className={skeletonStyles['chat-item']}>
            <SlSkeleton effect="pulse" className={skeletonStyles['chat-icon']}></SlSkeleton>

            <div className={skeletonStyles['chatlist-content']}>
                <SlSkeleton effect="pulse" className={skeletonStyles['chat-text']}></SlSkeleton>
                <SlSkeleton effect="pulse" className={skeletonStyles['chat-text']}></SlSkeleton>
            </div>
        </div>
    );
};
