import { FC } from 'react';
import dividerStyles from './Divider.module.scss';

interface DividerProps {
    title: string;
}

export const Divider: FC<DividerProps> = ({ title }) => {
    return (
        <div className={dividerStyles['content-divider']}>
            <div className={dividerStyles['content-divider-line']}></div>
            <div className={dividerStyles['content-divider-title']}>{title}</div>
            <div className={dividerStyles['content-divider-line']}></div>
        </div>
    );
};
