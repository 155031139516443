import { makeStyles, shorthands } from "@fluentui/react-components";

export const usePromptDialogClasses = makeStyles({
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto', // align to right
    },
    text: {
        width: '100%',
        overflowWrap: 'break-word',
    },
});