import React from 'react';
import { Button, Tooltip } from '@fluentui/react-components';

import { Icon } from '@iconify/react';
import { IChatMessage } from '../../../libs/models/ChatMessage';

interface CopyButtonProps {
    message: IChatMessage;
}
const CopyButton: React.FC<CopyButtonProps> = ({ message }) => {
    const [messagedCopied, setMessageCopied] = React.useState(false);

    React.useEffect(() => {
        setMessageCopied(false);
    }, [message]);

    const copyOnClick = () => {
        void navigator.clipboard.writeText(message.content).then(() => {
            setMessageCopied(true);
        });
    };

    return (
        <Tooltip content={messagedCopied ? 'Copied' : 'Copy text'} relationship="label">
            <Button
                icon={<Icon icon="lets-icons:copy-light" color={messagedCopied ? 'var(--core-jade)' : 'inherit'} />}
                appearance="transparent"
                onClick={copyOnClick}
            />
        </Tooltip>
    );
};

export default React.memo(CopyButton);
