import { makeStyles, shorthands } from '@fluentui/react-components';

export const usePopOverClasses = makeStyles({
    root: {
        width: '300px',
        ...shorthands.gap('8px'),
        '& *': {
            fontFamily: 'IBM Plex Sans',
        },
    },
    headerwrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontFamily: 'IBM Plex Sans',
    },
    titlewrapper: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('8px'),
    },
    closebutton: {
        width: 'fit-content',
        height: 'fit-content',
        cursor: 'pointer',
        ...shorthands.border('none'),
        backgroundColor: 'transparent',
        alignItems: 'center',
        display: 'flex',
    },
    content: {
        fontFamily: 'IBM Plex Sans',
    },
    actionswrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontFamily: 'IBM Plex Sans',
    },
    buttonswrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...shorthands.gap('8px'),
    },
    checkbox: {
        '& *': {
            fontFamily: 'IBM Plex Sans',
        },
    },
});
