import { Button, Tooltip } from '@fluentui/react-components';
import { Icon } from '@iconify/react';
import SlTabGroup from '@shoelace-style/shoelace/dist/components/tab-group/tab-group';
import { FC } from 'react';

import { useCreationCompClasses } from '../../../styles/assistantCreation/creationComponents';
import { useAHClasses } from '../../../styles/assistantHub/assHubElements';
import { useButtonClasses } from '../../../styles/global/button';
import { neighborsTabsNames } from './helper';
import './creationTabs.scss';

interface CreativityTabProps {
    creativityLevel: number;
    tabGroupRef: React.RefObject<SlTabGroup>;
    setCreativityLevel: (creativityLevel: number) => void;
    currentTabName: string;
}

const CheckIcon: FC = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="9" fill="#37E17B" />
                <path d="M8 12L11 15L16 9" stroke="black" />
            </svg>
        </div>
    );
};

const CreativityTab: FC<CreativityTabProps> = ({
    creativityLevel,
    setCreativityLevel,
    tabGroupRef,
    currentTabName,
}) => {
    const { previous, next } = neighborsTabsNames[currentTabName];

    const onBack = () => {
        tabGroupRef.current?.show(previous);
    };

    const onNext = () => {
        tabGroupRef.current?.show(next);
    };

    const onSelect = (cardName: string) => {
        if (cardName === 'precise') {
            setCreativityLevel(0.1);
        } else if (cardName === 'balanced') {
            setCreativityLevel(0.5);
        } else {
            setCreativityLevel(0.9);
        }
    };

    const buttonClasses = useButtonClasses();

    const AHClasses = useAHClasses();
    const creationCompClasses = useCreationCompClasses();

    return (
        <div className="tab-wrapper">
            <div className="content-wrapper">
                <div>
                    <div className="header-wrapper" style={{ marginBottom: '16px' }}>
                        <h2
                            style={{
                                margin: '0',
                            }}
                        >
                            How creative should your assistant be?
                        </h2>
                        <Tooltip
                            withArrow
                            content={
                                'Adjusting the creativity affects how varied the response from the assistant will be'
                            }
                            relationship={'description'}
                            aria-label="creativity tip"
                        >
                            <Icon
                                icon="lets-icons:info-light"
                                style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                            />
                        </Tooltip>
                    </div>
                    <p className={AHClasses.description} style={{ marginBottom: '16px' }}>
                        If unsure, the &quot;More balanced&quot; setting strikes a nice balance between creativity and
                        practicality.
                    </p>
                    <div className="cards-container">
                        <div
                            onClick={() => {
                                onSelect('precise');
                            }}
                            className={creationCompClasses.creativityCard}
                            style={{
                                backgroundColor:
                                    creativityLevel === 0.1
                                        ? 'var(--extended-grass-in-bright-light)'
                                        : 'var(--neutral-midnight-50)',
                            }}
                        >
                            <h3 style={{ margin: 0 }}>More precise</h3>
                            <hr style={{ margin: '16px 0' }}></hr>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <CheckIcon />
                                For factual tasks.
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <CheckIcon />
                                Recommended when uploading documents.
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                onSelect('balanced');
                            }}
                            className={creationCompClasses.creativityCard}
                            style={{
                                backgroundColor:
                                    creativityLevel === 0.5
                                        ? 'var(--extended-grass-in-bright-light)'
                                        : 'var(--neutral-midnight-50)',
                            }}
                        >
                            <h3 style={{ margin: 0 }}>More balanced</h3>
                            <hr style={{ margin: '16px 0' }}></hr>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <CheckIcon />
                                For general use.
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <CheckIcon />A mix of accuracy and creativity.
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                onSelect('creative');
                            }}
                            className={creationCompClasses.creativityCard}
                            style={{
                                backgroundColor:
                                    creativityLevel === 0.9
                                        ? 'var(--extended-grass-in-bright-light)'
                                        : 'var(--neutral-midnight-50)',
                            }}
                        >
                            <h3 style={{ margin: 0 }}>More creative</h3>
                            <hr style={{ margin: '16px 0' }}></hr>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <CheckIcon />
                                    For innovative tasks.
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <CheckIcon />
                                    Imaginative and outside of the box.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="button-wrapper" style={{ marginTop: '24px' }}>
                    <Button className={buttonClasses.secondaryButtonLarge} onClick={onBack}>
                        Back
                    </Button>
                    <Button className={buttonClasses.primaryButtonLarge} onClick={onNext}>
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreativityTab;
