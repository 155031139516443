import React, { FC } from 'react';

import { useAppDispatch } from '../../redux/app/hooks';
import { setSelectedConversation } from '../../redux/features/conversations/conversationsSlice';
import { PopUpDialog } from '../popups/PopUpDialog';
import { useGoPage } from '../../libs/hooks/useRouterNavigate';

const HomeButton: FC = () => (
    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} aria-label="home button">
        <img src="/sapience-home-logo.svg" alt="sapience logo" style={{ height: '30px' }} />
    </div>
);

const Iterai: React.FC = () => {
    const { goStartPage, isAssistantCreateRoute, isAssistantEditRoute } = useGoPage();
    const dispatch = useAppDispatch();

    const handleOnClick = () => {
        dispatch(setSelectedConversation('start'));
        goStartPage();
    };

    let message = '';
    if (isAssistantCreateRoute) message = 'By exiting assistant creation, all progress will be lost.';
    if (isAssistantEditRoute) message = 'When you exit editing the assistant, all progress will be lost.';

    return isAssistantCreateRoute || isAssistantEditRoute ? (
        <PopUpDialog
            header={'Unsaved changes'}
            content={message}
            buttonText={'Exit'}
            onAction={handleOnClick}
            canHide={false}
        >
            <HomeButton />
        </PopUpDialog>
    ) : (
        <div onClick={handleOnClick}>
            <HomeButton />
        </div>
    );
};

export default Iterai;
