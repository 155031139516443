import { FluentProvider, makeStyles, shorthands } from '@fluentui/react-components';

import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { FeatureKeys } from '../../redux/features/app/AppState';
import { semanticKernelDarkTheme, semanticKernelLightTheme } from '../../styles/global/styles';
import HeaderComponent from '../Header/Header';
import App from '../../App';

export const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        ...shorthands.overflow('hidden'),
    },
});

const AppLayout = () => {
    const classes = useClasses();
    const { features } = useAppSelector((state: RootState) => state.app);

    return (
        <FluentProvider
            className="app-container"
            theme={features[FeatureKeys.DarkMode].enabled ? semanticKernelDarkTheme : semanticKernelLightTheme}
        >
            <div className={classes.container}>
                <HeaderComponent />
                <App />
            </div>
        </FluentProvider>
    );
};

export default AppLayout;
