export const isMobile = () => {
    return window.innerWidth <= 576;
};

export const isTablet = () => {
    const { innerWidth } = window;
    return innerWidth > 576 && innerWidth <= 1024;
};

export const isDesktop = () => {
    return window.innerWidth > 1024;
};
