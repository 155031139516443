import { BaseService } from './BaseService';

interface WorkGenResponse {
    value: string;
}

export class WorkGenService extends BaseService {
    private static instance: WorkGenService;

    private constructor() {
        super();
    }

    // Static method to provide global point of access
    public static getInstance(): WorkGenService {
        if (!WorkGenService.instance) {
            WorkGenService.instance = new WorkGenService();
        }
        return WorkGenService.instance;
    }

    public createWorkDescriptionAsync = async (input: string, accessToken: string): Promise<string> => {
        const body = {
            input,
        };

        const result = await this.getResponseAsync<WorkGenResponse>(
            {
                commandPath: 'promptService',
                method: 'POST',
                body,
            },
            accessToken,
        );

        return result.value;
    };
}
