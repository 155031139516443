import SlSkeleton from '@shoelace-style/shoelace/dist/react/skeleton';

import skeletonStyles from './assistantListItemSkeleton.module.scss';

export const AssistantListItemSkeleton = () => {
    return (
        <div className={skeletonStyles['assistant-item']}>
            <SlSkeleton effect="pulse" className={skeletonStyles['assistant-icon']}></SlSkeleton>
            <SlSkeleton effect="pulse" className={skeletonStyles['assistant-text']}></SlSkeleton>
        </div>
    );
};
