import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';
import { useChat } from '../../libs/hooks';
import { AlertType } from '../../libs/models/AlertType';
import { useAppDispatch, useConversationByIdSelector } from '../../redux/app/hooks';
import { addAlert } from '../../redux/features/app/appSlice';
import { editConversationTitle } from '../../redux/features/conversations/conversationsSlice';
import { Checkmark20, Dismiss20 } from '../shared/BundledIcons';

import spinnerStyles from '../global/spinner.module.scss';
import editNameStyles from './editChatName.module.scss';

interface IEditChatNameProps {
    name: string;
    chatId: string;
    exitEdits: () => void;
}

export const EditChatName: React.FC<IEditChatNameProps> = ({ name, chatId, exitEdits }) => {
    const dispatch = useAppDispatch();
    const { selectedId } = useParams();
    const conversation = useConversationByIdSelector(chatId);
    const { editChat } = useChat('EditChatName');

    const [title = '', setTitle] = useState<string | undefined>(name);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (selectedId !== chatId) exitEdits();
    }, [chatId, exitEdits, selectedId]);

    const changeTitleAndSave = () => {
        if (name == title || !conversation) {
            exitEdits();
            return;
        }
        setIsSaving(true);
        const { lastMessage, systemDescription, serviceId, responseTemperature, memoryBalance } = conversation;
        editChat(chatId, lastMessage, title, systemDescription, serviceId, responseTemperature, memoryBalance)
            .then(() => {
                dispatch(editConversationTitle({ id: chatId, newTitle: title }));
            })
            .catch(() => {
                const errorMessage = `Unable to retrieve chat to change title.`;
                dispatch(addAlert({ message: errorMessage, type: AlertType.Error, isToast: true }));
            })
            .finally(onFinishSave);
    };

    const onFinishSave = () => {
        exitEdits();
        setIsSaving(false);
        console.log('should have closed');
    };
    const onClose = () => {
        setTitle(name);
        exitEdits();
    };

    const onTitleChange = (event: CustomEvent) => {
        const target = event.target as HTMLInputElement;
        setTitle(target.value);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
        if (event.key === 'Enter') {
            changeTitleAndSave();
        }
    };

    return (
        <div
            className={editNameStyles['edit-body']}
            title={'Edit chat name'}
            aria-label={`Edit chat name for "${name}"`}
        >
            <SlInput
                className={editNameStyles['edit-input']}
                value={title}
                onSlInput={onTitleChange}
                onKeyDown={handleKeyDown}
                maxlength={30}
                autoFocus
                id={`input-${chatId}`}
                aria-label="Enter new name"
            />
            <div className={editNameStyles['actions-wrapper']}>
                <SlButton
                    className={editNameStyles['action-button']}
                    variant="text"
                    aria-label="Cancel"
                    onClick={onClose}
                >
                    <Dismiss20 />
                </SlButton>
                {isSaving ? (
                    <SlSpinner style={{ fontSize: '18px' }} className={spinnerStyles['default-spinner']} />
                ) : (
                    <SlButton
                        className={editNameStyles['action-button']}
                        variant="text"
                        aria-label="Save"
                        onClick={changeTitleAndSave}
                    >
                        <Checkmark20 />
                    </SlButton>
                )}
            </div>
        </div>
    );
};
