import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { Constants } from './Constants';
import './index.css';
import { AuthConfig, AuthHelper } from './libs/auth/AuthHelper';
import { store } from './redux/app/store';

import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { BackendServiceUrl } from './libs/services/BaseService';
import { setAuthConfig } from './redux/features/app/appSlice';
import { router } from './router';

// import * as Sentry from '@sentry/react';

if (!localStorage.getItem('debug')) {
    localStorage.setItem('debug', `${Constants.debug.root}:*`);
}

let container: HTMLElement | null = null;
let root: ReactDOM.Root | undefined = undefined;
let msalInstance: PublicClientApplication | undefined;

// Sentry.init({
//     dsn: 'https://5cea9f9c158eb9b62d2f6f81638e853e@o4507259281539072.ingest.de.sentry.io/4507259285143632',
//     integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: [/^https:\/\/app-copichat-5lignuk4rmwte-webapi\.azurewebsites.\net/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

document.addEventListener('DOMContentLoaded', () => {
    if (!container) {
        container = document.getElementById('root');
        if (!container) {
            throw new Error('Could not find root element');
        }
        root = ReactDOM.createRoot(container);

        renderApp();
    }
});

const renderWithoutMsal = () => {
    root?.render(
        <React.StrictMode>
            <ReduxProvider store={store}>
                <RouterProvider router={router} />
            </ReduxProvider>
        </React.StrictMode>,
    );
};

export function renderApp() {
    fetch(new URL('authConfig', BackendServiceUrl))
        .then((response) =>
            response.ok ? (response.json() as Promise<AuthConfig>) : Promise.reject(Error('error occurred')),
        )
        .then((authConfig) => {
            store.dispatch(setAuthConfig(authConfig));

            if (AuthHelper.isAuthAAD()) {
                if (!msalInstance) {
                    msalInstance = new PublicClientApplication(AuthHelper.getMsalConfig(authConfig));
                    void msalInstance.handleRedirectPromise().then((response) => {
                        if (response) {
                            msalInstance?.setActiveAccount(response.account);
                        }
                    });
                }

                // render with the MsalProvider if AAD is enabled
                root?.render(
                    <React.StrictMode>
                        <ReduxProvider store={store}>
                            <MsalProvider instance={msalInstance}>
                                <RouterProvider router={router} />
                            </MsalProvider>
                        </ReduxProvider>
                    </React.StrictMode>,
                );
            } else {
                renderWithoutMsal();
            }
        })
        .catch(() => {
            store.dispatch(setAuthConfig(undefined));
            renderWithoutMsal();
        });
    return null;
}
