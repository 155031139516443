import { makeStyles, shorthands } from "@fluentui/react-components";
import { customTokens } from "../../global/styles";

export const useCitationCardClasses = makeStyles({
    root: {
        display: 'flex',
        ...shorthands.gap(customTokens.spacingVerticalS),
        flexDirection: 'column',
    },
    card: {
        display: 'flex',
        width: '100%',
        height: 'fit-content',
    },
});