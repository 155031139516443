import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import React from 'react';

// Rest of the code
import { useAssistants } from '../../libs/hooks/useAssistants';
import { useGoPage } from '../../libs/hooks/useRouterNavigate';
import { useAssistantByIdSelector } from '../../redux/app/hooks';
import tooltipStyles from '../global/tooltip.module.scss';
import { PopUpDialog } from '../popups/PopUpDialog';
import assistantlistStyles from './AssistantListItem.module.scss';

export type TCreateNewChat = (
    name: string,
    systemDescription?: string,
    serviceId?: string,
    responseTemperature?: number,
    isAssistant?: boolean,
) => void;

interface IAssistantListItemProps {
    assistantId: string;
    handleNewChat: TCreateNewChat;
    disabled?: boolean;
}

const AssistantListItem: React.FC<IAssistantListItemProps> = ({ assistantId, handleNewChat, disabled }) => {
    const { deleteAssistantAsync, editAssistantAsync } = useAssistants('AssistantListItem');
    const { goEditAssistant } = useGoPage();
    const assistant = useAssistantByIdSelector(assistantId);

    const [isPoppingOver, setIsPoppingOver] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClick = () => {
        if (!isPoppingOver) {
            handleNewChat(
                assistant.title,
                assistant.systemDescription,
                assistant.serviceId,
                assistant.responseTemperature,
                true,
            );
        }
    };

    const handleEdit = () => {
        goEditAssistant(assistant.id);
        setIsPoppingOver(false);
    };

    const handleDelete = () => {
        void deleteAssistantAsync(assistant.id);
    };

    const handleHide = () => {
        void editAssistantAsync(
            assistant.title,
            assistant.systemDescription,
            assistant.responseTemperature,
            assistant.id,
            false,
            assistant.serviceId,
        );
    };

    const popOverButton = (handleButtonClick: () => void, contentText: string, contentIcon: string) => {
        return (
            <SlButton className={assistantlistStyles['menu-item']} onClick={handleButtonClick} variant="text">
                <div className={assistantlistStyles['assistant-icon-container']}>
                    <Icon
                        icon={'lets-icons:' + contentIcon + '-light'}
                        width="24px"
                        height="24px"
                        style={{ marginRight: '6px' }}
                    />
                    <p>{contentText}</p>
                </div>
            </SlButton>
        );
    };

    return (
        <div className={assistantlistStyles['assistant-item']}>
            <SlButton
                className={assistantlistStyles['start-chat-button']}
                onClick={handleClick}
                disabled={disabled}
                variant="text"
            >
                <img slot="prefix" src="/AI.svg" />
                {assistant.title}
            </SlButton>
            <SlTooltip
                content={'Edit ' + assistant.title}
                placement="right"
                className={tooltipStyles['sapience-tooltip']}
            >
                <SlDropdown
                    onMouseEnter={() => {
                        setIsPoppingOver(true);
                    }}
                    onMouseLeave={() => {
                        setIsPoppingOver(false);
                    }}
                    onSlShow={() => {
                        setIsOpen(true);
                    }}
                    placement="bottom-end"
                    open={isOpen}
                >
                    <SlButton slot="trigger" variant="text" className={assistantlistStyles['meatball-button']}>
                        <Icon icon="lets-icons:meatballs-menu" width={24} height={24} />
                    </SlButton>
                    <SlMenu className={assistantlistStyles['assistant-menu']}>
                        {popOverButton(handleEdit, 'Edit', 'edit')}
                        {popOverButton(handleHide, 'Hide', 'view-hide')}
                        <PopUpDialog
                            header="Delete assistant"
                            content={`Are you sure you want to delete this assistant: ${assistant.title}?`}
                            buttonText="Delete"
                            onAction={handleDelete}
                            canHide={false}
                        >
                            {popOverButton(() => {}, 'Delete', 'trash')}
                        </PopUpDialog>
                    </SlMenu>
                </SlDropdown>
            </SlTooltip>
        </div>
    );
};

export default AssistantListItem;
