import SlButton from '@shoelace-style/shoelace/dist/react/button';
import React, { FC } from 'react';
import './buttons.scss';

interface ButtonProps {
    children: React.ReactNode;
    secondary?: boolean;
    small?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    className?: string;
}

export const DefaultButton: FC<ButtonProps> = ({ children, secondary, small, disabled, onClick, className }) => {
    const buttonClassName = `${className ? className : ''} ${secondary ? 'secondary' : 'primary'}${
        small ? '-small' : '-large'
    }`;
    return (
        <SlButton onClick={onClick} disabled={disabled} className={buttonClassName}>
            {children}
        </SlButton>
    );
};
