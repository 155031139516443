import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAssistant } from '../../../libs/models/Assistants';
import { AssistantsState, initialState } from './AssistantsState';
import { EStatus } from '../conversations/ConversationsState';

export const assistantsSlice = createSlice({
    name: 'assistants',
    initialState,
    reducers: {
        setAssistantsStatus: (state: AssistantsState, action: PayloadAction<EStatus>) => {
            state.status = action.payload;
        },
        setAssistantsError: (state: AssistantsState, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setAssistants: (state: AssistantsState, action: PayloadAction<Record<string, IAssistant>>) => {
            state.assistants = action.payload;
        },
        addAssistant: (state: AssistantsState, action: PayloadAction<IAssistant>) => {
            state.assistants[action.payload.id] = action.payload;
        },
        editAssistantName: (state: AssistantsState, action: PayloadAction<{ id: string; newName: string }>) => {
            const id = action.payload.id;
            const newName = action.payload.newName;
            state.assistants[id].title = newName;
        },
        editAssistant: (state: AssistantsState, action: PayloadAction<IAssistant>) => {
            const id = action.payload.id;
            state.assistants[id] = action.payload;
        },
        deleteAssistant: (state: AssistantsState, action: PayloadAction<string>) => {
            const { [action.payload]: _, ...remainingAssistants } = state.assistants;
            state.assistants = remainingAssistants;
        },
    },
});

export const {
    setAssistantsStatus,
    setAssistantsError,
    setAssistants,
    addAssistant,
    editAssistantName,
    deleteAssistant,
    editAssistant,
} = assistantsSlice.actions;

export default assistantsSlice.reducer;
