import { makeStyles, shorthands } from '@fluentui/react-components';
import { Breakpoints, customTokens } from '../../global/styles';

export const useChatHistoryItemClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '75%',
        minWidth: '24em',
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
        ...Breakpoints.small({
            maxWidth: '100%',
        }),
        ...shorthands.gap(customTokens.spacingHorizontalXS),
        ...shorthands.margin('0 auto'),
    },
    debug: {
        position: 'absolute',
        top: '-4px',
        right: '-4px',
    },
    alignEnd: {
        alignSelf: 'flex-end',
    },
    persona: {
        paddingTop: 0,
    },
    profileBubble: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...shorthands.borderRadius('50%'),
        width: '36px',
        height: '36px',
        fontFamily: 'ibm plex sans',
        fontWeight: 600,
        fontSize: '14px',
        flexShrink: 0,
    },
    item: {
        backgroundColor: 'white',
        ...shorthands.borderRadius('4px'),
        ...shorthands.padding('10px'),
        ...shorthands.border('1px', 'solid', 'var(--neutral-midnight-60)'),
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('8px'),
        minWidth: '200px',
        lineHeight: '18px',
    },
    time: {
        color: customTokens.colorNeutralForeground3,
        fontSize: customTokens.fontSizeBase200,
        fontWeight: 400,
    },
    header: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap(customTokens.spacingHorizontalL),
        alignItems: 'center',
    },
    canvas: {
        width: '100%',
        textAlign: 'center',
    },
    image: {
        maxWidth: '250px',
    },
    blur: {
        filter: 'blur(5px)',
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: customTokens.spacingVerticalS,
        marginBottom: customTokens.spacingVerticalS,
        ...shorthands.gap(customTokens.spacingHorizontalL),
    },
    citationButton: {
        marginRight: 'auto',
    },
    rlhf: {
        marginLeft: 'auto',
    },
});
