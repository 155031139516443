import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useTokenUsageLegendItemClasses = makeStyles({
    root: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingVerticalSNudge),
        alignItems: 'center',
    },
    colors: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingVerticalXXS),
    },
    legendColor: {
        height: tokens.spacingVerticalMNudge,
        width: tokens.spacingHorizontalMNudge,
    },
});