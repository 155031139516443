import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const usePlanStepInputClasses = makeStyles({
    root: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
    },
    buttons: {
        ...shorthands.padding(tokens.spacingVerticalNone),
        paddingLeft: tokens.spacingHorizontalXXS,
        minWidth: '18px',
    },
    input: {
        ...shorthands.margin(tokens.spacingHorizontalXXS),
        maxHeight: '10px',
        minHeight: '10px',
        fontSize: '12px',
    },
    interactable: {
        zIndex: '50',
    },
});