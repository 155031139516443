import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlTab from '@shoelace-style/shoelace/dist/react/tab';
import SlTabGroup from '@shoelace-style/shoelace/dist/react/tab-group';
import SlTabPanel from '@shoelace-style/shoelace/dist/react/tab-panel';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useChat } from '../../../libs/hooks';
import { useAssistants } from '../../../libs/hooks/useAssistants';
import { useGoPage } from '../../../libs/hooks/useRouterNavigate';
import { AlertType } from '../../../libs/models/AlertType';
import { IAssistant } from '../../../libs/models/Assistants';
import { useAppDispatch, useAssistantByIdSelector } from '../../../redux/app/hooks';
import { addAlert } from '../../../redux/features/app/appSlice';
import { PopUpDialog } from '../../popups/PopUpDialog';
import { GPTServiceId } from '../../utils/GPTModels';
import AssistantDescriptionTab from './AssistantDescriptionTab';
import CreativityTab from './CreativityTab';
import EditTab from './EditTab';
import NameTab from './NameTab';
import SelectGPTModel from './SelectGPTModel';
import SummaryTab from './SummaryTab';

import '../../global/buttons.scss';
import './creationTabs.scss';
import { functionalTabs } from './helper';

const PACreation: React.FC = () => {
    const tabGroupRef = useRef(null);

    const [promptInput, setPromptInput] = React.useState('');
    const [workDescription, setWorkDescription] = React.useState('');
    const [creativityLevel, setCreativityLevel] = React.useState(0.5);
    const [modelName, setModelName] = React.useState<GPTServiceId>('gpt4o');
    const [assistantName, setName] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [id, setId] = React.useState<string>('');
    const [active, setActive] = React.useState(true);
    const assistants = useAssistants('PACreation');
    const { createChat } = useChat('PACreation');
    const dispatch = useAppDispatch();
    const { assistantId } = useParams();
    const assistant = useAssistantByIdSelector(assistantId);
    const { isAssistantEditRoute, goStartPage, goManageAssistant } = useGoPage();

    const dispatchAlert = (message: string, type: AlertType) => {
        dispatch(addAlert({ message, type }));
    };

    const setFunctions = {
        setWorkDescription: setWorkDescription,
        setCreativityLevel: setCreativityLevel,
        setModelName: setModelName,
        setName: setName,
    };

    const successHandler = (a: IAssistant) => {
        dispatchAlert(`Assistant ${isAssistantEditRoute ? 'edited' : 'created'} successfully`, AlertType.Success);
        if (a.active) {
            void createChat(assistantName, workDescription, modelName, creativityLevel, true);
        } else {
            goManageAssistant();
        }
    };
    const errorHandler = (error: Error) => {
        console.error(error);
        dispatchAlert(error.message, AlertType.Error);
    };
    const finalHandler = () => {
        setIsLoading(false);
    };

    const handleCreateAssistant = () => {
        setIsLoading(true);

        (isAssistantEditRoute
            ? assistants.editAssistantAsync(assistantName, workDescription, creativityLevel, id, active, modelName)
            : assistants.createAssistantAsync(assistantName, workDescription, creativityLevel, modelName)
        )
            .then(successHandler)
            .catch(errorHandler)
            .finally(finalHandler);
    };

    useEffect(() => {
        if (isAssistantEditRoute && assistant) {
            setWorkDescription(assistant.systemDescription);
            setCreativityLevel(assistant.responseTemperature);
            setName(assistant.title);
            setId(assistant.id);
            setActive(assistant.active);
            setModelName(assistant.serviceId);
        }
    }, [assistant, isAssistantEditRoute]);

    // Render the tabs for creating a new assistant
    if (!isAssistantEditRoute) {
        return (
            <div
                style={{
                    width: '100%',
                    padding: '0',
                    height: '100%',
                }}
            >
                <SlTabGroup ref={tabGroupRef} className="creation-tabs" style={{ height: '100%' }}>
                    {/* Using a disabled tab as a wrapper for text at start */}
                    <SlTab slot="nav" disabled className="pa-tab-text">
                        Build your assistant
                    </SlTab>

                    {functionalTabs.map(({ panelName, title }, index) => (
                        <SlTab key={panelName} slot="nav" panel={panelName} className="tab">
                            {`${index + 1}: ${title}`}
                        </SlTab>
                    ))}

                    {/* Using a final disabled tab as a wrapper for the Close button */}
                    <SlTab slot="nav" className="pa-tab-close-button-container" disabled>
                        <PopUpDialog
                            header={'Unsaved changes'}
                            content={'By exiting assistant creation, all progress will be lost.'}
                            buttonText={'Exit'}
                            onAction={goStartPage}
                            canHide={false}
                        >
                            <SlButton className="secondary-small">
                                <Icon icon="lets-icons:close-round-light" width="24px" height="24px" />
                                Close
                            </SlButton>
                        </PopUpDialog>
                    </SlTab>

                    <SlTabPanel name="prompt" className="creation-tabs-panel">
                        <AssistantDescriptionTab
                            currentTabName="prompt"
                            tabGroupRef={tabGroupRef}
                            setWorkDescription={setWorkDescription}
                            workDescription={workDescription}
                            promptInput={promptInput}
                            setPromptInput={setPromptInput}
                        />
                    </SlTabPanel>
                    <SlTabPanel name="creativity" className="creation-tabs-panel">
                        <CreativityTab
                            currentTabName="creativity"
                            tabGroupRef={tabGroupRef}
                            setCreativityLevel={setCreativityLevel}
                            creativityLevel={creativityLevel}
                        />
                    </SlTabPanel>
                    <SlTabPanel name="model" className="creation-tabs-panel">
                        <SelectGPTModel
                            currentTabName="model"
                            tabGroupRef={tabGroupRef}
                            modelName={modelName}
                            setModelName={setModelName}
                        />
                    </SlTabPanel>
                    <SlTabPanel name="name" className="creation-tabs-panel">
                        <NameTab
                            currentTabName="name"
                            assistantName={assistantName}
                            tabGroupRef={tabGroupRef}
                            setName={setName}
                        />
                    </SlTabPanel>
                    <SlTabPanel name="summary" className="creation-tabs-panel">
                        <SummaryTab
                            currentTabName="summary"
                            assistantName={assistantName}
                            workDescription={workDescription}
                            creativityLevel={creativityLevel.toString()}
                            modelName={modelName}
                            setName={setName}
                            handleCreate={handleCreateAssistant}
                            isLoading={isLoading}
                            tabGroupRef={tabGroupRef}
                        />
                    </SlTabPanel>
                </SlTabGroup>
            </div>
        );

        // Render the tabs for editing an existing assistant
    } else {
        return (
            <div
                style={{
                    width: '100%',
                    padding: '0',
                    height: '100%',
                }}
            >
                <SlTabGroup ref={tabGroupRef} className="creation-tabs">
                    {/* Using a disabled tab as a wrapper for text at start */}
                    <SlTab slot="nav" disabled className="pa-tab-text">
                        Edit your assistant
                    </SlTab>

                    {/* Functional single tab */}
                    <SlTab slot="nav" panel="edit" active className="tab-no-display">
                        Edit
                    </SlTab>

                    {/* Using a final disabled tab as a wrapper for the Close button */}
                    <SlTab slot="nav" className="pa-tab-close-button-container" disabled>
                        <PopUpDialog
                            header={'Unsaved changes'}
                            content={'By exiting this page, all progress will be lost.'}
                            buttonText={'Exit'}
                            onAction={goStartPage}
                            canHide={false}
                        >
                            <SlButton className="secondary-small">
                                <Icon icon="lets-icons:close-round-light" width="24px" height="24px" />
                                Close
                            </SlButton>
                        </PopUpDialog>
                    </SlTab>

                    <SlTabPanel name="edit">
                        <EditTab
                            assistantName={assistantName}
                            workDescription={workDescription}
                            creativityLevel={creativityLevel}
                            modelName={modelName}
                            handleCreate={handleCreateAssistant}
                            setFunctions={setFunctions}
                            isLoading={isLoading}
                        />
                    </SlTabPanel>
                </SlTabGroup>
            </div>
        );
    }
};

export default PACreation;
