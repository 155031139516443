export const tutorialImages = [
    'tutorial/Slide 1.png',
    'tutorial/Slide 2.png',
    'tutorial/Slide 3.png',
    'tutorial/Slide 4.png',
    'tutorial/Slide 5.png',
    'tutorial/Slide 6.png',
    'tutorial/Slide 7.png',
    'tutorial/Slide 8.png',
    'tutorial/Slide 9.png',
    'tutorial/Slide 10.png',
    'tutorial/Slide 11.png',
    'tutorial/Slide 12.png',
    'tutorial/Slide 13.png',
    'tutorial/Slide 14.png',
    'tutorial/Slide 15.png'];

export const tutorialHeaders = [
    <span key='about-page-slide-1' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-2' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-3' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-4' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-5' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-6' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-7' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-8' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-9' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-10' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-11' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-12' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-13' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-14' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>,
    <span key='about-page-slide-15' style={{fontSize: '20px'}}><strong>Sapience essentials</strong></span>];