import { makeStyles, shorthands } from '@fluentui/react-components';

export const useAHClasses = makeStyles({
    description: {
        fontFamily: 'IBM Plex Sans',
        fontSize: '14px',
    },

    // Usage: <div className={useAHClasses.divider} ></div>
    divider: {
        ...shorthands.borderBottom('1px', 'solid', '#EDEDED'),
        width: '100%',
        ...shorthands.margin('24px 0'),
    },

    section: {
        ...shorthands.padding('16px'),
        ...shorthands.borderRadius('4px'),
        //borderColor: 'blue',
        backgroundColor: 'white',
        marginTop: '16px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },

    personalAssistantsContainer: {
        width: '100%',
        display: 'grid',
        flexWrap: 'wrap',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #fff',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    },
});
