import { useMsal } from '@azure/msal-react';
import * as speechSdk from 'microsoft-cognitiveservices-speech-sdk';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Outlet } from 'react-router-dom';

import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { useChat } from '../../../libs/hooks';
import { useGoPage } from '../../../libs/hooks/useRouterNavigate';
import { AlertType } from '../../../libs/models/AlertType';
import { SpeechService } from '../../../libs/services/SpeechService';
import { useAppDispatch, useAppSelector, useConversationsListSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { addAlert, setShowSideBar } from '../../../redux/features/app/appSlice';
import { setNavigation } from '../../../redux/features/navigation/navigationSlice';
import OnboardingModal from '../../modal/OnboardingModal';
import { onboardingBodies, onboardingImages, whoAmIBodies, whoAmIImages } from '../../modal/OnboardingModalData';
import { getErrorDetails } from '../../utils/TextUtils';
import { ViewStates } from '../ViewStates';

import { AssistantsList } from './AssistantsList';
import { ChatsList } from './ChatsList';
import { DetailsDropdown } from './DetailsDropdown';
import { Divider } from './Divider';

import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlTextarea from '@shoelace-style/shoelace/dist/react/textarea';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import startPageClasses from './StartPage.module.scss';

import toolTipStyles from '../../global/tooltip.module.scss';
import UpdateDialog from './UpdateDialog';

export const StartPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const { goStartPage } = useGoPage();
    const { goCreateAssistant } = useGoPage();
    const { navigation } = useAppSelector((state: RootState) => state.navigation);
    const { showSideBar } = useAppSelector((state: RootState) => state.app);
    const { assistants } = useAppSelector((state: RootState) => state.assistants);
    const conversationsList = useConversationsListSelector();

    const enableWhoAmI = [ViewStates.StartIntro, ViewStates.StartWhoAmI].includes(navigation);

    const toggleSideBar = () => {
        dispatch(setShowSideBar(!showSideBar));
    };

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    // Check if onboarding is required.
    const [cookies, setCookie] = useCookies(['COMPLETED_ONBOARDING']);
    if (!cookies.COMPLETED_ONBOARDING && !isDialogOpen) {
        setIsDialogOpen(true);
    }

    const goToStartPage = () => {
        dispatch(setNavigation(ViewStates.Start));
        goStartPage();
    };

    const closeDialog = () => {
        setCookie('COMPLETED_ONBOARDING', true, { maxAge: 60 * 60 * 24 * 365 * 10, path: '/' });
        toggleSideBar();
        setIsDialogOpen(false);
    };

    const chat = useChat('StartPage');
    const [value, setValue] = useState('');
    const [creatingNewChat, setCreatingNewChat] = useState(false);
    const { instance, inProgress } = useMsal();

    const [isListening, setIsListening] = useState(false);
    const [isWriting, setIsWriting] = useState(false);
    const [recognizer, setRecognizer] = useState<speechSdk.SpeechRecognizer>();
    const [model, setModel] = useState('GPT-4o mini');
    const [creativity, setCreativity] = useState('Balanced');

    const handleCreativityChange = (event: CustomEvent) => {
        setCreativity((event.target as HTMLInputElement).value);
    };

    const handleModelChange = (event: CustomEvent) => {
        setModel((event.target as HTMLInputElement).value);
    };

    // ---- When the user clicks on the microphone button
    const handleSpeech = () => {
        setIsListening(true);
        if (recognizer) {
            recognizer.recognizeOnceAsync((result) => {
                if (result.reason === speechSdk.ResultReason.RecognizedSpeech) {
                    if (result.text && result.text.length > 0) {
                        setValue(result.text);
                        setIsWriting(true);
                    }
                }
                setIsListening(false);
            });
        }
    };

    // ---- When the user sends a message
    const handleNewChat = () => {
        const serviceId = model === 'GPT-4o mini' ? 'gpt4oMini' : 'gpt4o';
        const creativityValue = creativity === 'Balanced' ? 0.5 : creativity === 'Precise' ? 0.1 : 0.9;
        setCreatingNewChat(true);
        void chat.createChatThenSetToFalse(setCreatingNewChat, '', '', serviceId, creativityValue, false, value);
    };

    // ---- When the user types in the chat input field
    const onChange = (event: CustomEvent) => {
        const target = event.target as HTMLInputElement;
        setValue(target.value);

        if (target.value.length > 0) {
            setIsWriting(true);
        } else {
            setIsWriting(false);
        }
    };

    React.useEffect(() => {
        async function initSpeechRecognizer() {
            const speechService = SpeechService.getInstance();
            const response = await speechService.getSpeechTokenAsync(
                await AuthHelper.getSKaaSAccessToken(instance, inProgress),
            );
            if (response.isSuccess) {
                const recognizer = speechService.getSpeechRecognizerAsyncWithValidKey(response);
                setRecognizer(recognizer);
            }
        }

        initSpeechRecognizer().catch((e) => {
            const errorDetails = getErrorDetails(e);
            const errorMessage = `Unable to initialize speech recognizer. Details: ${errorDetails}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        });
    }, [dispatch, instance, inProgress]);

    return (
        <div className={startPageClasses['start-page-section']}>
            <div className={startPageClasses['start-page-elipse-upper-corner-bg']} />
            <div className={startPageClasses['start-page-elipse-lower-corner-bg']} />
            <div className={startPageClasses['start-page-body']}>
                <h1 className={startPageClasses['header-root']}>
                    <span className={startPageClasses['jade-text']}>Hey,</span>
                    <span>&nbsp;let&apos;s do some stuff.</span>
                </h1>
                <div className={startPageClasses['page-content']}>
                    <div className={startPageClasses['start-chat-section']}>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                paddingBottom: 40,
                            }}
                        >
                            {/*  ---- Enable text to speech if input field is empty ---- */}
                            {recognizer && !isWriting && (
                                <SlTooltip content={'Text to speech'} className={toolTipStyles['sapience-tooltip']}>
                                    <SlButton
                                        aria-label="Start speech to text"
                                        variant="text"
                                        disabled={isListening || creatingNewChat}
                                        onClick={handleSpeech}
                                        className={startPageClasses['text-to-speech-button']}
                                    >
                                        <Icon icon="lets-icons:mic-light" width="24px" height="24px" />
                                    </SlButton>
                                </SlTooltip>
                            )}
                            {/* ---- Disable text to speech if input field is not empty ---- */}
                            {(!recognizer || isWriting) && (
                                <SlTooltip content={'Send your message'} className={toolTipStyles['sapience-tooltip']}>
                                    <SlButton
                                        title="Submit"
                                        aria-label="Submit message"
                                        variant="text"
                                        disabled={isListening || creatingNewChat}
                                        onClick={handleNewChat}
                                        className={startPageClasses['send-button']}
                                    >
                                        <Icon icon="lets-icons:send-hor-light" width="24px" height="24px" />
                                    </SlButton>
                                </SlTooltip>
                            )}

                            <SlTextarea
                                title="Chat input"
                                aria-label="Chat input field. Click enter to submit input."
                                id="chat-input"
                                placeholder="I am ready for your questions, instructions and requests."
                                label="Start a new chat"
                                value={value}
                                resize="auto"
                                rows={1}
                                onFocus={() => {
                                    // update the locally stored value to the current value
                                    const chatInput = document.getElementById('chat-input');
                                    if (chatInput) {
                                        setValue((chatInput as HTMLTextAreaElement).value);
                                    }
                                }}
                                onSlInput={onChange}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' && !event.shiftKey && !creatingNewChat) {
                                        event.preventDefault();
                                        handleNewChat();
                                    }
                                }}
                                className={startPageClasses['chat-input']}
                            />
                            <DetailsDropdown
                                creativity={creativity}
                                model={model}
                                handleCreativityChange={handleCreativityChange}
                                handleModelChange={handleModelChange}
                            />
                        </div>
                    </div>
                    <Divider title="Chat with your assistants" />
                    {/* Assistants grid */}
                    <AssistantsList assistants={assistants} />
                    <SlButton onClick={goCreateAssistant} className={startPageClasses['create-assistant-button']}>
                        Create an assistant
                    </SlButton>
                    <Divider title="Recent chats" />
                    <ChatsList conversations={conversationsList} />
                </div>
            </div>

            <Outlet />

            <OnboardingModal
                isOpen={enableWhoAmI}
                onClose={goToStartPage}
                bodies={whoAmIBodies}
                images={whoAmIImages}
                slideNr={0}
            />
            <OnboardingModal
                isOpen={isDialogOpen}
                onClose={closeDialog}
                bodies={onboardingBodies}
                images={onboardingImages}
                slideNr={0}
            />

            {!isDialogOpen && <UpdateDialog cutoffDate="2024-09-01" header="Introducing GPT-4o mini" />}
        </div>
    );
};
