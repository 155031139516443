import { makeStyles, shorthands } from "@fluentui/react-components";

export const useChatViewClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
        maxWidth: '100%'
    },
});