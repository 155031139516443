import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useLoadingClasses = makeStyles({
  
  loadingHorizontal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...shorthands.gap(tokens.spacingVerticalXL),
    marginTop: tokens.spacingVerticalXXXL,
    width: '100%',
  },

  loadingVertical: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#000833',
    zIndex: '2'
  },

  loading: {
    width: '25vw',
    '& .ftywsgz': {
      backgroundColor: 'rgba(17, 221, 118, 100)'
    },
  }
});
